import React, { useRef, useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./DropdownSearch.module.sass";
import Tooltip from "../Tooltip";
import Loader from "../Loader";
import { BiX } from "react-icons/bi";

const DropdownSearch = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  isBackgroundWhite,
  label,
  tooltip,
  small,
  upBody,
  isLoading,
  isDelete,
  onSearch,
  character,
  timeoutSearch = 500,
}) => {
  const [visible, setVisible] = useState(false);
  const timeoutRef = useRef(null);

  const handleClick = (value) => {
    setValue(value);
    setVisible(false);
  };

  const handleSearch = (ev) => {
    if (!visible) setVisible(true);
    if (onSearch) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        onSearch(ev.target.value);
      }, timeoutSearch);
    }
  };

  console.log("este es value dropdown", value)

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label} {tooltip && <Tooltip className={styles.tooltip} title={tooltip} icon="info" place="right" />}
        </div>
      )}
      <div
        style={{zIndex: 999}}
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div style={isBackgroundWhite ? {background: 'white', display: 'flex', alignItems: 'center', width: '100%'} : { display: 'flex', alignItems: 'center', width: '100%' }} className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
          <div className={styles.selection}>{value?.label}{<input className={styles.inputSearch} onChange={handleSearch} />}</div>
          {isDelete &&
            <BiX style={{fontSize: '16px', position: 'absolute', right: -20, cursor: 'pointer'}} onClick={()=>setValue('')} />
          }
        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
          {isLoading ? (
            <div className={cn(styles.loaderContainer)}>
              <Loader />
            </div>
          ) : options.length === 0 ? (
            <div className={cn(styles.loaderContainer)}>No results</div>
          ) : (
            <>
            {visible &&
              options.map((op, index) => (
                <div
                  className={cn(styles.option, {
                    [styles.selectioned]: op.value === value,
                  })}
                  onClick={() => handleClick(op, index)}
                  key={index}
                >
                  <div className={cn(styles.character)}>{op.character}</div>
                  {op.label}
                </div>
              ))
            }
            </>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default DropdownSearch;

import React from 'react'
import { useEffect } from 'react'
import { useHistory } from "react-router-dom";

const Redirecting = () => {

    const history = useHistory();

    const queryParams = new URLSearchParams(window.location.search)
    const auth = queryParams.get("auth")

    useEffect(() => {
        if(auth){
            localStorage.setItem('user', JSON.stringify(JSON.parse(auth)))
            history.push('/')
        }
    }, [auth])


    return (
        <div>
            Redirecting...
        </div>
    );
}

export default Redirecting;
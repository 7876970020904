import React from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import Theme from "../../components/Theme";
import { Controller, useForm } from "react-hook-form";
import { fetchLoginUser } from "../../app/user/user.slice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";

const SignIn = () => {
  const { user } = useSelector((state) => state);
  const heightWindow = use100vh();
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();

  const onSubmit = (data) => {
    dispatch(fetchLoginUser(data));
  };

  return (
    <div className={styles.wrapperContainer}>
      <img src="/images/login-back-image.png" className={styles.wrapperContainerImg} />
      <div className={styles.login} style={{ minHeight: heightWindow }}>
        <div className={styles.wrapper}>
          <Link className={styles.logo} to="/">
            <Image className={styles.pic} src="/images/logo-dark.png" srcDark="/images/logo-light.png" alt="Core" />
          </Link>
          <div className={cn("h2", styles.title)}>Administración</div>
          <div className={styles.head}>
            <div className={styles.subtitle}>Conectate con Google </div>
            <div className={styles.btns}>
              <button onClick={()=>window.open(`${process.env.REACT_APP_API_URL}/auth/external/google/redirect`, '_self')} className={cn("button-stroke", styles.button)}>
                <img src="/images/content/google.svg" alt="Google" />
                Google
              </button>
              {/* <button className={cn("button-stroke", styles.button)}>
              <Image
                className={styles.pic}
                src="/images/content/apple-dark.svg"
                srcDark="/images/content/apple-light.svg"
                alt="Apple"
              />
              Apple ID
            </button> */}
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.subtitle}>O con tu correo electrónico</div>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextInput
                  className={styles.field}
                  name="email"
                  type="email"
                  placeholder="Tu email"
                  required
                  icon="mail"
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextInput
                  className={styles.field}
                  name="password"
                  type="password"
                  placeholder="Contraseña"
                  required
                  icon="lock"
                  {...field}
                />
              )}
            />

            <button className={cn("button", styles.button)} onClick={handleSubmit(onSubmit)}>
              {user.isLoadingLogin ? <Loader /> : "Entrar"}
            </button>
            <div className={styles.note}></div>
            <Theme />
            {/* <div className={styles.info}>
            Don’t have an account?{" "}
            <Link className={styles.link} to="/sign-up">
              Sign up
            </Link>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;

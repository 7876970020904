import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import ProductsDashboard from "./screens/ProductsDashboard";
import Drafts from "./screens/Drafts";
import Released from "./screens/Released";
import Comments from "./screens/Comments";
import Scheduled from "./screens/Scheduled";
import Customers from "./screens/Customers";
import CustomerList from "./screens/CustomerList";
import Promote from "./screens/Promote";
import Notification from "./screens/Notification";
import Settings from "./screens/Settings";
import UpgradeToPro from "./screens/UpgradeToPro";
import MessageCenter from "./screens/MessageCenter";
import ExploreCreators from "./screens/ExploreCreators";
import AffiliateCenter from "./screens/AffiliateCenter";
import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import Earning from "./screens/Earning";
import Refunds from "./screens/Refunds";
import Payouts from "./screens/Payouts";
import Statements from "./screens/Statements";
import Shop from "./screens/Shop";
import PageList from "./screens/PageList";

import { useDispatch, useSelector } from "react-redux";
import { fetchLoginUser } from "./app/user/user.slice";
import { useEffect } from "react";
import Decks from "./screens/Decks";
import NewDeck from "./screens/NewDeck";
import { fetchDecks } from "./app/decks/decks.slice";
import Classes from "./screens/Classes";
import { fetchClasses } from "./app/classes/classes.slice";
import NewClasse from "./screens/NewClass";
import BugsReports from "./screens/BugsReports";
import Redirecting from "./screens/Redirecting/Redirecting";
import { fetchCategory } from "./app/category/category.slice";
import Cards from "./screens/Cards";
import NewCard from "./screens/NewCard";
import { fetchGrammar } from "./app/grammar/grammar.slice";
import { fetchClassification } from "./app/classification/classification.slice";
import { fetchBug } from "./app/bugs/bugs.slice";
import DeckStats from "./screens/DeckStats";
import { fetchUsers } from "./app/users/users.slice";
import UsersComponent from "./screens/Users";
import AddBug from "./screens/AddBug";
import Categories from "./screens/Categories";
import Notifications from "./screens/Notifications";
import EmailNotifications from "./screens/Notifications/add/Email";
import { BiChevronRight } from "react-icons/bi";
import { useHistory } from "react-router";
import PushNotifications from "./screens/Notifications/add/Push";
import InAppNotifications from "./screens/Notifications/add/InApp";
import Texts from "./screens/Texts";
import AddText from "./screens/Texts/Add";
// import Content from "./screens/Texts/Content";
import SocialHub from "./screens/SocialHub";
import DecksImages from "./screens/DecksImage";
import Content2 from "./screens/Texts/Content2";
import Feedback from "./screens/Feedback";
import { toast } from "sonner";
import { fetchFeedback } from "./app/feedback/feedback.slice";

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, decks } = useSelector((state) => state);

  console.log("decks --> ", decks);

  useEffect(() => {
    dispatch(fetchLoginUser());
  }, []);

  useEffect(() => {
    if (user.value) {
      if (user.value?.role === "USER") {
        localStorage.clear();
        toast.error("You don't have permissions.");
        window.open("/", "_self");
        return;
      }
      dispatch(fetchDecks());
      dispatch(fetchClasses());
      dispatch(fetchCategory());
      dispatch(fetchGrammar());
      dispatch(fetchClassification());
      dispatch(fetchBug());
      dispatch(fetchUsers());
      dispatch(fetchFeedback());
    }
  }, [user.value]);

  return (
    <Router>
      <Switch>
        {user.value === null ? (
          <>
            <Route exact path="/" render={() => <SignIn />} />
            <Route exact path="/sign-up" render={() => <SignUp />} />
            <Route exact path="/redirect_google" render={() => <Redirecting />} />
            <Route exact path="/bug/add" render={() => <AddBug isPadding />} />
          </>
        ) : (
          <>
            <Route
              exact
              path="/"
              render={() => (
                <Page title="Decks">
                  <Decks />
                  {/* <Drafts /> */}
                </Page>
              )}
            />
            <Route
              exact
              path="/deck/stats/:deckId"
              render={() => (
                <Page title="Stats">
                  <DeckStats />
                </Page>
              )}
            />
            <Route
              exact
              path="/deck/add"
              render={() => (
                <Page title="New deck">
                  <NewDeck isNew />
                </Page>
              )}
            />
            <Route
              exact
              path="/deck/edit/:deckId"
              render={() => (
                <Page title="Edit deck">
                  <NewDeck />
                </Page>
              )}
            />
            <Route
              exact
              path="/deck-images"
              render={() => (
                <Page title="Deck Images">
                  <DecksImages />
                  {/* <Drafts /> */}
                </Page>
              )}
            />
            <Route
              exact
              path="/cards"
              render={() => (
                <Page title="Cards">
                  <Cards />
                  {/* <Drafts /> */}
                </Page>
              )}
            />
            <Route
              exact
              path="/card/add"
              render={() => (
                <Page isPadding={false} title="New card">
                  <NewCard isNew />
                  {/* <Drafts /> */}
                </Page>
              )}
            />

            <Route
              exact
              path="/texts"
              render={() => (
                <Page title="Texts">
                  <Texts />
                  {/* <Drafts /> */}
                </Page>
              )}
            />

            <Route
              exact
              path="/texts/add"
              render={() => (
                <Page isPadding={false} title="Add new text">
                  <AddText />
                  {/* <Drafts /> */}
                </Page>
              )}
            />

            <Route
              exact
              path="/texts/edit/:id"
              render={() => (
                <Page isPadding={false} title={"Edit text"}>
                  <AddText isEdit />
                  {/* <Drafts /> */}
                </Page>
              )}
            />

            <Route
              exact
              path="/texts/content/:id"
              render={() => (
                <Page isHeader={false} isPadding={false} title="">
                  <Content2 />
                  {/* <Drafts /> */}
                </Page>
              )}
            />

            <Route
              exact
              path="/card/edit/:cardId"
              render={() => (
                <Page isPadding={false} title="Edit card">
                  <NewCard />
                  {/* <Drafts /> */}
                </Page>
              )}
            />

            <Route
              exact
              path="/notifications"
              render={() => (
                <Page title="Notifications">
                  <Notifications />
                </Page>
              )}
            />

            <Route
              exact
              path="/social-hub"
              render={() => (
                <Page title="Social Hub">
                  <SocialHub />
                </Page>
              )}
            />

            <Route
              exact
              path="/notifications/add/email"
              render={() => (
                <Page
                  title={
                    <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                      <div
                        onClick={() => window.open("/notifications", "_self")}
                        style={{ color: "gray", fontSize: 20, cursor: "pointer" }}
                      >
                        Notifications
                      </div>
                      <BiChevronRight size="20" style={{ color: "gray" }} />
                      <div style={{ color: "black", fontSize: 20 }}>Add new email</div>
                    </div>
                  }
                >
                  <EmailNotifications />
                </Page>
              )}
            />

            <Route
              exact
              path="/notifications/edit/email/:newId"
              render={() => (
                <Page
                  title={
                    <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                      <div
                        onClick={() => window.open("/notifications", "_self")}
                        style={{ color: "gray", fontSize: 20, cursor: "pointer" }}
                      >
                        Notifications
                      </div>
                      <BiChevronRight size="20" style={{ color: "gray" }} />
                      <div style={{ color: "black", fontSize: 20 }}>Edit email</div>
                    </div>
                  }
                >
                  <EmailNotifications />
                </Page>
              )}
            />

            <Route
              exact
              path="/notifications/add/push"
              render={() => (
                <Page
                  title={
                    <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                      <div
                        onClick={() => window.open("/notifications", "_self")}
                        style={{ color: "gray", fontSize: 20, cursor: "pointer" }}
                      >
                        Notifications
                      </div>
                      <BiChevronRight size="20" style={{ color: "gray" }} />
                      <div style={{ color: "black", fontSize: 20 }}>Add new push notification</div>
                    </div>
                  }
                >
                  <PushNotifications />
                </Page>
              )}
            />

            <Route
              exact
              path="/notifications/edit/push/:newId"
              render={() => (
                <Page
                  title={
                    <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                      <div
                        onClick={() => window.open("/notifications", "_self")}
                        style={{ color: "gray", fontSize: 20, cursor: "pointer" }}
                      >
                        Notifications
                      </div>
                      <BiChevronRight size="20" style={{ color: "gray" }} />
                      <div style={{ color: "black", fontSize: 20 }}>Edit Push notification</div>
                    </div>
                  }
                >
                  <PushNotifications />
                </Page>
              )}
            />

            <Route
              exact
              path="/notifications/add/inapp"
              render={() => (
                <Page
                  title={
                    <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                      <div
                        onClick={() => window.open("/notifications", "_self")}
                        style={{ color: "gray", fontSize: 20, cursor: "pointer" }}
                      >
                        Notifications
                      </div>
                      <BiChevronRight size="20" style={{ color: "gray" }} />
                      <div style={{ color: "black", fontSize: 20 }}>Add new In-App notification</div>
                    </div>
                  }
                >
                  <InAppNotifications />
                </Page>
              )}
            />

            <Route
              exact
              path="/notifications/edit/inapp/:newId"
              render={() => (
                <Page
                  title={
                    <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                      <div
                        onClick={() => window.open("/notifications", "_self")}
                        style={{ color: "gray", fontSize: 20, cursor: "pointer" }}
                      >
                        Notifications
                      </div>
                      <BiChevronRight size="20" style={{ color: "gray" }} />
                      <div style={{ color: "black", fontSize: 20 }}>Edit In-App notification</div>
                    </div>
                  }
                >
                  <InAppNotifications />
                </Page>
              )}
            />

            <Route
              exact
              path="/users"
              render={() => (
                <Page title="Users">
                  <UsersComponent />
                  {/* <Drafts /> */}
                </Page>
              )}
            />

            <Route
              exact
              path="/classes"
              render={() => (
                <Page title="Classes">
                  <Classes />
                  {/* <Drafts /> */}
                </Page>
              )}
            />
            <Route
              exact
              path="/classes/add"
              render={() => (
                <Page title="New class">
                  <NewClasse />
                </Page>
              )}
            />

            <Route
              exact
              path="/categories"
              render={() => (
                <Page title="Categories">
                  <Categories />
                </Page>
              )}
            />

            <Route
              exact
              path="/classes/edit/:classeId"
              render={() => (
                <Page title="Edit class">
                  <NewClasse />
                </Page>
              )}
            />

            <Route
              exact
              path="/bug-reports"
              render={() => (
                <Page title="Bugs / Reports">
                  <BugsReports />
                  {/* <Drafts /> */}
                </Page>
              )}
            />

            <Route
              exact
              path="/feedback"
              render={() => (
                <Page title="Feedback">
                  <Feedback />
                  {/* <Drafts /> */}
                </Page>
              )}
            />

            {/* <Route
              exact
              path="/"
              render={() => (
                <Page title="Dashboard">
                  <Home />
                </Page>
              )}
            /> */}
            <Route
              exact
              path="/products/dashboard"
              render={() => (
                <Page title="Products dashboard">
                  <ProductsDashboard />
                </Page>
              )}
            />

            <Route
              exact
              path="/products/drafts"
              render={() => (
                <Page title="Drafts">
                  <Drafts />
                </Page>
              )}
            />
            <Route
              exact
              path="/products/released"
              render={() => (
                <Page title="Released">
                  <Released />
                </Page>
              )}
            />
            <Route
              exact
              path="/products/comments"
              render={() => (
                <Page title="Comments">
                  <Comments />
                </Page>
              )}
            />
            <Route
              exact
              path="/products/scheduled"
              render={() => (
                <Page title="Scheduled">
                  <Scheduled />
                </Page>
              )}
            />
            <Route
              exact
              path="/customers/overview"
              render={() => (
                <Page title="Customers">
                  <Customers />
                </Page>
              )}
            />
            <Route
              exact
              path="/customers/customer-list"
              render={() => (
                <Page title="Customer list">
                  <CustomerList />
                </Page>
              )}
            />
            <Route
              exact
              path="/shop"
              render={() => (
                <Page wide>
                  <Shop />
                </Page>
              )}
            />
            <Route
              exact
              path="/income/earning"
              render={() => (
                <Page title="Earning">
                  <Earning />
                </Page>
              )}
            />
            <Route
              exact
              path="/income/refunds"
              render={() => (
                <Page title="Refunds">
                  <Refunds />
                </Page>
              )}
            />
            <Route
              exact
              path="/income/payouts"
              render={() => (
                <Page title="Payouts">
                  <Payouts />
                </Page>
              )}
            />
            <Route
              exact
              path="/income/statements"
              render={() => (
                <Page title="Statements">
                  <Statements />
                </Page>
              )}
            />
            <Route
              exact
              path="/promote"
              render={() => (
                <Page title="Promote">
                  <Promote />
                </Page>
              )}
            />
            <Route
              exact
              path="/notification"
              render={() => (
                <Page title="Notification">
                  <Notification />
                </Page>
              )}
            />
            <Route
              exact
              path="/settings"
              render={() => (
                <Page title="Settings">
                  <Settings />
                </Page>
              )}
            />
            <Route
              exact
              path="/upgrade-to-pro"
              render={() => (
                <Page title="Upgrade to Pro">
                  <UpgradeToPro />
                </Page>
              )}
            />
            <Route
              exact
              path="/message-center"
              render={() => (
                <Page title="Message center">
                  <MessageCenter />
                </Page>
              )}
            />
            <Route
              exact
              path="/explore-creators"
              render={() => (
                <Page title="Explore creators">
                  <ExploreCreators />
                </Page>
              )}
            />
            <Route
              exact
              path="/affiliate-center"
              render={() => (
                <Page title="Affiliate center">
                  <AffiliateCenter />
                </Page>
              )}
            />
          </>
        )}

        {/* <Route exact path="/" render={() => <SignIn />} /> */}
        {/* <Route exact path="/pagelist" component={PageList} /> */}
      </Switch>
    </Router>
  );
}

export default App;

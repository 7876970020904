import React from 'react'
import cn from "classnames";
import { BiError, BiRightArrowAlt } from 'react-icons/bi';
import styles from "./Report.module.sass";
import dayjs from 'dayjs';

const Report = ({ createdBy, date, bugType, device, reason, id, bug, handleSolve }) => {
    return (
        <div style={{
            border: '1px solid #EC5555',
            background: 'rgba(236, 85, 85, 0.1)',
            borderRadius: '8px',
            padding: '24px',
            position: 'relative',
        }}>
            <div style={{ display: 'flex', alignItems: 'center'}}>
                <BiError size="24" />
                <span style={{fontSize: 20, marginLeft: 8}} >
                    Card Reported
                </span>
            </div>
            <div style={{marginTop: 20, color: '#AF6363'}}>
                <span>
                    <u style={{color: '#681C1C'}}>
                        @{createdBy}
                    </u>
                    {' '}
                    reported this card on
                    {' '}
                    <span style={{color: '#681C1C'}}>
                        {dayjs(date).format('DD MMM YYYY, HH:mm')}
                    </span>
                    {' '}
                    with the following error
                    {' '}
                    <span style={{color: '#681C1C'}}>
                        "{bugType}"
                    </span>
                </span>
            </div>

            <div style={{marginTop: 30, color: '#AF6363', display: 'flex', alignItems: 'center'}} >
                <span>
                    Device
                </span>
                <BiRightArrowAlt size="18" style={{marginLeft: 3}} />
                <span style={{color: '#681C1C', marginLeft: 3}}>
                    {device}
                </span>
            </div>
            <div style={{marginTop: 10, color: '#AF6363', display: 'flex', alignItems: 'center'}} >
                <span>
                    Comment
                </span>
                <BiRightArrowAlt size="18" style={{marginLeft: 3}} />
                <span style={{color: '#681C1C', marginLeft: 3}}>
                    {reason}
                </span>
            </div>

            <div style={{ position: 'absolute', right: '24px', bottom: '24px' }}>
                <button onClick={()=>handleSolve(bug)} className={cn("button-stroke", styles.button, styles.backgroundWhite)}>
                    Mark as solved
                </button>
            </div>
        </div>
    );
}

export default Report;
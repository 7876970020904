import React, { useState } from "react";
import styles from "./Sidebar.module.sass";
import { BiCarousel, BiBook, BiError, BiCreditCardAlt, BiUserCircle, BiCabinet, BiBell, BiFontFamily, BiShareAlt, BiImages, BiListOl, BiVideo } from 'react-icons/bi'
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Help from "./Help";
import Image from "../Image";
import { useSelector } from "react-redux";
import Modal from "../Modal";
import { MdOutlineFeedback } from "react-icons/md";


const Sidebar = ({ className, onClose }) => {

  
  
  const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);
  
  const [isVisibleOnboarding, setIsVisibleOnboarding] = useState(false)
  
  const { user, bugs } = useSelector((state)=> state)
  
  
  const navigation = [
    {
      title: "Decks",
      icon: BiCarousel,
      addTo: "/deck/add",
      add: true,
      role: ["TEACHER", "ADMIN"],
      addRole: ["TEACHER", "ADMIN"],
      urlDropdown: '/',
      dropdown: [
        {
          title: "Decks List",
          icon: BiListOl,
          role: ["TEACHER", "ADMIN"],
          addRole: ["TEACHER", "ADMIN"],
          url: "/",
        },
        {
          title: "Deck Images",
          icon: BiImages,
          role: ["TEACHER", "ADMIN"],
          addRole: ["TEACHER", "ADMIN"],
          url: "/deck-images",
        }
      ]
    },
    {
      title: "Users",
      icon: BiUserCircle,
      addTo: "/deck/add",
      add: false,
      role: ["ADMIN"],
      addRole: ["ADMIN"],
      url: "/users",
    },
    {
      title: "Categories",
      icon: BiCabinet,
      add: false,
      role: ["ADMIN"],
      addRole: ["ADMIN"],
      url: "/categories",
    },
    {
      title: "Cards",
      icon: BiCreditCardAlt,
      add: true,
      addTo: "/card/add",
      role: ["TEACHER", "ADMIN"],
      addRole: ["ADMIN"],
      url: "/cards",
    },
    {
      title: "Texts",
      icon: BiFontFamily,
      role: ["ADMIN"],
      add: true,
      addTo: "/texts/add",
      addRole: ["ADMIN"],
      url: "/texts",
    },
    {
      title: "Classes",
      icon: BiBook,
      add: true,
      addTo: "/classes/add",
      role: ["TEACHER", "ADMIN"],
      addRole: ["ADMIN"],
      url: "/classes",
    },
    {
      title: "Bugs / Reports",
      icon: BiError,
      role: ["TEACHER", "ADMIN"],
      counterBug: true,
      colorCounter: "#B5E4CA",
      addRole: ["TEACHER", "ADMIN"],
      url: "/bug-reports",
    },
    {
      title: "Notifications",
      icon: BiBell,
      role: ["ADMIN"],
      addRole: ["ADMIN"],
      url: "/notifications",
    },
    {
      title: "Feedback",
      icon: MdOutlineFeedback,
      role: ["ADMIN"],
      addRole: ["ADMIN"],
      url: "/feedback",
    },
    {
      title: "Social Hub",
      icon: BiShareAlt,
      role: ["ADMIN"],
      addRole: ["ADMIN"],
      url: "/social-hub",
    },
    {
      title: "Onboarding",
      icon: BiVideo,
      role: ["TEACHER", "ADMIN"],
      addRole: ["TEACHER", "ADMIN"],
      url: "/",
      onClick: () => setIsVisibleOnboarding(true)
    },
    // {
    //   title: "Products",
    //   slug: "products",
    //   icon: "diamond",
    //   add: true,
    //   dropdown: [
    //     {
    //       title: "Dashboard",
    //       url: "/products/dashboard",
    //     },
    //     {
    //       title: "Drafts",
    //       url: "/products/drafts",
    //       counter: "2",
    //       colorCounter: "#FFBC99",
    //     },
    //     {
    //       title: "Released",
    //       url: "/products/released",
    //     },
    //     {
    //       title: "Comments",
    //       url: "/products/comments",
    //     },
    //     {
    //       title: "Scheduled",
    //       url: "/products/scheduled",
    //       counter: "8",
    //       colorCounter: "#B5E4CA",
    //     },
    //   ],
    // },
    // {
    //   title: "Customers",
    //   slug: "customers",
    //   icon: "profile-circle",
    //   dropdown: [
    //     {
    //       title: "Overview",
    //       url: "/customers/overview",
    //     },
    //     {
    //       title: "Customer list",
    //       url: "/customers/customer-list",
    //     },
    //   ],
    // },
    // {
    //   title: "Shop",
    //   icon: "store",
    //   url: "/shop",
    // },
    // {
    //   title: "Income",
    //   slug: "income",
    //   icon: "pie-chart",
    //   dropdown: [
    //     {
    //       title: "Earning",
    //       url: "/income/earning",
    //     },
    //     {
    //       title: "Refunds",
    //       url: "/income/refunds",
    //     },
    //     {
    //       title: "Payouts",
    //       url: "/income/payouts",
    //     },
    //     {
    //       title: "Statements",
    //       url: "/income/statements",
    //     },
    //   ],
    // },
    // {
    //   title: "Promote",
    //   icon: "promotion",
    //   url: "/promote",
    // },
  ];

  return (
    <>
      <div className={cn(styles.sidebar, className, { [styles.active]: visible })}>
        <Modal visible={isVisibleOnboarding} onClose={() => setIsVisibleOnboarding(false)}>
            <div>
                <div style={{fontSize: 16, fontWeight: 'bold', marginTop: 10}}>
                    Onboarding
                </div>
                <div style={{ fontSize: 14, color: 'gray', marginTop: 10}}>
                  <video width="100%" height="650" controls>
                    <source src="https://boostchineseprod.s3.eu-west-3.amazonaws.com/marketing/onboarding/onboarding.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
            </div>
        </Modal>
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <div style={{position: 'relative'}}>
          <Link className={styles.logo} to="/" onClick={onClose}>
            <div style={{ display: 'flex', alignItems: 'center', fontSize: 28, color: '#AAAAAA' }}>
              <Image className={styles.pic} src="/images/logo-dark.png" srcDark="/images/logo-light.png" alt="Core" />
              <span style={{marginLeft: 15, textTransform: 'capitalize'}}>
                {user?.value?.role}
              </span>
              {process.env.REACT_APP_API_URL.includes('dev') &&
                <div style={{borderRadius: '6px', marginLeft: 15, fontSize: 15, paddingInline: '8px', background: '#EC55551A', color: '#EC5555'}}>
                  DEV
                </div>
              }
            </div>
          </Link>
        </div>
        <div className={styles.menu}>
          {navigation?.filter(c => c?.role?.includes(user?.value?.role)).map((x, index) =>
            x.url ? (
              <>
                {x?.onClick ? (
                  <div className={styles.item} style={{cursor: 'pointer'}} onClick={x.onClick}>
                    <x.icon size="24" />
                    {x.title}
                  </div>
                ) : (
                  <NavLink
                    className={styles.item}
                    activeClassName={styles.active}
                    to={x.url}
                    key={index}
                    exact
                    onClick={onClose}
                  >
                    <x.icon size="24" />
                    {x.title}
                    {(x?.add && x?.addRole?.includes(user?.value?.role)) &&
                      <Link
                        className={cn(styles.add)}
                        to={x?.addTo}
                      >
                        <Icon name="plus" size="10" style={{transform: 'translateX(0.4em)'}} />
                      </Link>
                    }
                    {(x?.counterBug && bugs?.value?.filter(c => c?.active)?.length > 0) &&
                      <div className={cn(styles.add)} style={{transform: 'translate(0em, -0.8em)', justifyContent: 'center', display: 'flex', alignItems: 'center', backgroundColor: '#B5E4CA', border: 'none', borderRadius: '8px'}}>
                        {bugs?.value?.filter(c => c?.active)?.length}
                      </div>
                    }
                  </NavLink>
                )}
              </>
            ) : (
              <Dropdown
                className={styles.dropdown}
                visibleSidebar={visible}
                setValue={setVisible}
                key={index}
                item={x}
                onClose={onClose}
              />
            )
          )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          {/* <button className={styles.link} onClick={() => setVisibleHelp(true)}>
            <Icon name="help" size="24" />
            Help & getting started
            <div className={styles.counter}>8</div>
          </button> */}
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      <Help visible={visibleHelp} setVisible={setVisibleHelp} onClose={onClose} />
      <div className={cn(styles.overlay, { [styles.active]: visible })} onClick={() => setVisible(false)}></div>
    </>
  );
};

export default Sidebar;

import React, { useState } from "react";
import styles from "./Chinese.module.sass";
import Card from "../../../../../components/Card";
import Checkbox from "../../../../../components/Checkbox";
import classNames from "classnames";
import Switch from "../../../../../components/Switch";
import Popover from "../../../../../components/Popover";
import Popover2 from "../../../../../components/Popover2";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchCards } from "../../../../../app/cards/cards.slice";
import TextInput from "../../../../../components/TextInput";
import { nanoid } from "nanoid";
import Modal from "../../../../../components/Modal";
import CardPreview from "../../../../../components/CardPreview";

const levelColors = {
  Newbie: "#4690FF",
  Begginer: "#83BF6E",
  Intermediate: "",
  Advanced: "",
  Master: "#FF4646",
};

const characters = {
  character: "character",
  lineBreak: "SC",
  special: "SC",
  name: "name",
};

const SPECIAL_CARDS = [" ", ",", "，", "%", ":", ":", "：", "、"];
const LINE_BREAK_CARDS = ["。", ".", "!", "！", "?", "？", ";", "；"];

const LANGS = ["en", "es", "fr", "ru", "pt"];

const Chinese = ({
  chapter,
  editarCapitulo,
  editarFraseDeCapitulo,
  anadirFraseACapitulo,
  anadirCardAFrase,
  editarTraduccionDeFrase,
  eliminarFraseDeCapitulo,
  eliminarCardDeFrase,
  chapterIndexSelected,
  anadirCardDespuesDeOtra,
  cambiarCardDeFrase,
  myRefs,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { cards } = useSelector((state) => state);

  const [viewLevel, setViewLevel] = useState(true);
  const [viewPinyin, setViewPinyin] = useState(true);

  const [isModalAddCard, setIsModalAddCard] = useState(false);

  const [isModalChangeSpecialCard, setIsModalChangeSpecialCard] = useState(false);

  const [isChangeCard, setIsChangeCard] = useState(false);

  const [selectedCard, setSelectedCard] = useState("");
  const [visibleModal, setVisibleModal] = useState(false);

  const [indiceFraseSelected, setIndiceFraseSelected] = useState(0);
  const [indiceCardSelected, setIndiceCardSelected] = useState(0);

  useEffect(() => {
    dispatch(
      fetchCards({
        take: 5,
        skip: 0,
      })
    );
  }, []);

  const handleSearchCard = (e) => {
    dispatch(
      fetchCards({
        take: 5,
        skip: 0,
        search: e,
      })
    );
  };

  function extraerNumero(str) {
    const resultado = str.match(/\d+/); // Esta expresión regular busca uno o más dígitos
    return resultado ? parseInt(resultado[0]) : null;
  }

  const handleGetColorLevel = (lvl) => {
    const COLORS = {
      1: "#83BF6E",
      2: "#78CEFF",
      3: "#5E5BF6",
      4: "#EDE63E",
      5: "#F0B43F",
      6: "#F05D5D",
      7: "#C055E5",
    };

    if (lvl?.title) {
      return COLORS?.[extraerNumero(lvl?.title)] || "#d3d3d3";
    } else {
      return "#d3d3d3";
    }
  };

  const handleAddNewSentence = () => {
    let translations = [];

    LANGS.forEach((lang) => {
      translations.push({
        active: lang === "en" ? true : false,
        lang,
        trans: "",
      });
    });

    const uniqueObjects = { cards: [], processRawText: false, text: "", translations, id: nanoid() };

    anadirFraseACapitulo(chapterIndexSelected, uniqueObjects);
  };

  const handleMouseEnter = (e, index, i) => {
    try {
      document.getElementById(`card_tooltip_${index}_${i}`).style.display = "block";
    } catch (err) {
      console.log(err);
    }
  };

  const handleMouseLeave = (e, index, i) => {
    try {
      document.getElementById(`card_tooltip_${index}_${i}`).style.display = "none";
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal outerClassName={styles.outer} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <div>
          <CardPreview card={selectedCard} />
        </div>
      </Modal>
      <Modal
        outerClassName={styles.outer}
        visible={isModalChangeSpecialCard}
        onClose={() => setIsModalChangeSpecialCard(false)}
      >
        <div>Select Special Char</div>
        <div
          className={styles.listSentence}
          style={{
            borderRadius: "12px",
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            alignItems: "center",
            backgroundColor: "#FFF",
            border: "2px solid lightgray",
            padding: "16px",
            paddingBottom: "28px",
            position: "relative",
            marginTop: 40,
          }}
        >
          {SPECIAL_CARDS?.map((c, i) => (
            <div
              onClick={() => {
                cambiarCardDeFrase(chapterIndexSelected, indiceFraseSelected, indiceCardSelected, {
                  available: false,
                  card: null,
                  character: c,
                  suggestions: [],
                  type: "special",
                });
                setIsModalChangeSpecialCard(false);
              }}
              key={i}
              style={{ position: "relative" }}
            >
              <div tabindex={i} style={{ transform: i === 0 ? "translateY(0.45em)" : "" }} className={styles.charCard}>
                <div
                  style={{
                    width: "20px",
                    height: "4px",
                    borderRadius: "999px",
                    backgroundColor: "#d3d3d3",
                    marginInline: "auto",
                  }}
                ></div>
                <div
                  style={{
                    color: "#A1A1A1",
                    fontWeight: 500,
                    fontSize: "12px",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: 4,
                  }}
                >
                  SC
                </div>
                <div
                  style={{
                    color: "#1D1D1D",
                    fontWeight: 400,
                    lineHeight: "20px",
                    fontSize: "18px",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: 4,
                  }}
                >
                  {c}
                </div>
              </div>
            </div>
          ))}
          {LINE_BREAK_CARDS?.map((c, i) => (
            <div
              onClick={() => {
                cambiarCardDeFrase(chapterIndexSelected, indiceFraseSelected, indiceCardSelected, {
                  available: false,
                  card: null,
                  character: c,
                  suggestions: [],
                  type: "lineBreak",
                });
                setIsModalChangeSpecialCard(false);
              }}
              key={i}
              style={{ position: "relative" }}
            >
              <div tabindex={i} style={{ transform: i === 0 ? "translateY(0.45em)" : "" }} className={styles.charCard}>
                <div
                  style={{
                    width: "20px",
                    height: "4px",
                    borderRadius: "999px",
                    backgroundColor: "#d3d3d3",
                    marginInline: "auto",
                  }}
                ></div>
                <div
                  style={{
                    color: "#A1A1A1",
                    fontWeight: 500,
                    fontSize: "12px",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: 4,
                  }}
                >
                  SC
                </div>
                <div
                  style={{
                    color: "#1D1D1D",
                    fontWeight: 400,
                    lineHeight: "20px",
                    fontSize: "18px",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: 4,
                  }}
                >
                  {c}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal>
      <Modal outerClassName={styles.outer} visible={isModalAddCard} onClose={() => setIsModalAddCard(false)}>
        <div>
          <TextInput
            type="text"
            required
            placeholder="Search card..."
            onChange={(e) => handleSearchCard(e.target.value)}
          />
          {cards?.value?.map((c, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                borderBottom: "1px solid #EFEFEF",
                paddingBottom: 15,
                paddingTop: 15,
              }}
            >
              <div style={{ display: "flex", alignItems: "start" }}>
                <div
                  style={{
                    background: "#F1F1F1",
                    fontWeight: 400,
                    fontSize: "18px",
                    borderRadius: "8px",
                    width: "56px",
                    height: "56px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {c?.meaning?.character}
                </div>
                <div style={{ marginLeft: 20, width: "200px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <b>{c?.meaning?.pinyin}</b>
                    <span style={{ marginLeft: 10, color: "#9A9FA5" }}>{c?.meaning?.pinyinTone}</span>
                  </div>
                  <div style={{ color: "#6F767E" }}>{c?.meaning?.trans}</div>
                </div>
              </div>
              <span
                onClick={() => {
                  if (isChangeCard) {
                    cambiarCardDeFrase(chapterIndexSelected, indiceFraseSelected, indiceCardSelected, {
                      available: true,
                      card: c,
                      character: c?.meaning?.character,
                      suggestions: [],
                    });
                  } else {
                    anadirCardDespuesDeOtra(chapterIndexSelected, indiceFraseSelected, indiceCardSelected, {
                      available: true,
                      card: c,
                      character: c?.meaning?.character,
                      suggestions: [],
                    });
                  }
                  setIsModalAddCard(false);
                }}
                style={{ color: "#2A85FF", fontSize: "16px", letterSpacing: "-1%", cursor: "pointer" }}
              >
                {isChangeCard ? "Change" : "+ Add"}
              </span>
            </div>
          ))}
        </div>
      </Modal>
      <Card
        title="Chinese"
        classTitle="title-blue"
        head={
          <div style={{ display: "flex", alignItems: "center" }} className={classNames(styles.nav, "tablet-hide")}>
            <div style={{ display: "flex", alignItems: "center", marginRight: 30 }}>
              <span style={{ marginRight: 10 }}>View level</span>
              <Switch value={viewLevel} onChange={() => setViewLevel(!viewLevel)} />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: 10 }}>View pinyin</span>
              <Switch value={viewPinyin} onChange={() => setViewPinyin(!viewPinyin)} />
            </div>
          </div>
        }
      >
        <div style={{ minHeight: "65vh" }} className={styles.listSentence}>
          {chapter.sentences?.map((sentence, index) => (
            <div
              ref={(el) => (myRefs.current[index] = el)}
              key={index}
              className={styles.listSentence}
              style={{
                borderRadius: "12px",
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                alignItems: "center",
                backgroundColor: "#F4F4F4",
                border: "2px solid lightgray",
                padding: "16px",
                paddingBottom: "28px",
                position: "relative",
              }}
            >
              {sentence?.cards?.map((c, i) => (
                <div
                  key={i}
                  onMouseEnter={(e) => handleMouseEnter(e, index, i)}
                  onMouseLeave={(e) => handleMouseLeave(e, index, i)}
                  style={{ position: "relative" }}
                >
                  <div
                    id={`card_tooltip_${index}_${i}`}
                    style={{
                      position: "absolute",
                      display: "none",
                      top: 0,
                      transform: "translateY(-100%)",
                      width: "auto",
                      minWidth: "200px",
                      boxShadow: "0px 6px 25px -8px rgba(186,186,186,1)",
                      zIndex: 5,
                      left: 0,
                      backgroundColor: "white",
                      padding: 12,
                      borderRadius: 8,
                    }}
                  >
                    <div
                      style={{ display: "flex", fontSize: 14, alignItems: "center", justifyContent: "space-between" }}
                    >
                      <div style={{ color: "gray" }}>TP</div>
                      <div style={{ color: c?.card?.grammars?.[0]?.title ? "black" : "red" }}>
                        {c?.card?.grammars?.[0]?.title || "Err"}
                      </div>
                    </div>
                    <div
                      style={{ display: "flex", fontSize: 14, alignItems: "center", justifyContent: "space-between" }}
                    >
                      <div style={{ color: "gray" }}>TR</div>
                      <div
                        style={{
                          color: c?.card?.meaning?.translations?.find((trans) => trans?.lang === "en")?.trans
                            ? "black"
                            : "red",
                        }}
                      >
                        {c?.card?.meaning?.translations?.find((trans) => trans?.lang === "en")?.trans || "Err"}
                      </div>
                    </div>
                    <div
                      style={{ display: "flex", fontSize: 14, alignItems: "center", justifyContent: "space-between" }}
                    >
                      <div style={{ color: "gray" }}>Level</div>
                      <div style={{ color: c?.level?.title ? "black" : "red" }}>{c?.level?.title || "Err"}</div>
                    </div>
                  </div>
                  <Popover2
                    actionable={
                      <div
                        tabindex={i}
                        style={{ transform: i === 0 ? "translateY(0.45em)" : "" }}
                        className={styles.charCard}
                      >
                        {viewLevel && (
                          <div
                            style={{
                              width: "20px",
                              height: "4px",
                              borderRadius: "999px",
                              backgroundColor: handleGetColorLevel(c?.level) || "#d3d3d3",
                              marginInline: "auto",
                            }}
                          ></div>
                        )}
                        {viewPinyin && (
                          <div
                            style={{
                              color:
                                c?.type === "name"
                                  ? "#7B61FF"
                                  : c?.card?.meaning?.pinyin
                                  ? "#A1A1A1"
                                  : c?.type === "special" || c?.type === "lineBreak"
                                  ? "#A1A1A1"
                                  : "red",
                              fontWeight: 500,
                              fontSize: "12px",
                              justifyContent: "center",
                              display: "flex",
                              marginTop: 4,
                            }}
                          >
                            {c?.card?.meaning?.pinyin || characters?.[c?.type] || "Err"}
                          </div>
                        )}
                        <div
                          style={{
                            color: "#1D1D1D",
                            fontWeight: 400,
                            lineHeight: "20px",
                            fontSize: "18px",
                            justifyContent: "center",
                            display: "flex",
                            marginTop: 4,
                          }}
                        >
                          {c?.character}
                        </div>
                      </div>
                    }
                    items={[
                      {
                        title: "Preview card",
                        icon: "expand",
                        action: () => {
                          setSelectedCard(c?.card);
                          setVisibleModal(true);
                        },
                      },
                      {
                        title: "Edit card",
                        icon: "edit",
                        action: () => {
                          setIsChangeCard(true);
                          setIndiceFraseSelected(index);
                          setIndiceCardSelected(i);
                          setIsModalAddCard(true);
                        },
                      },
                      {
                        title: "Convert Special",
                        icon: "edit",
                        action: () => {
                          setIndiceFraseSelected(index);
                          setIndiceCardSelected(i);
                          setIsModalChangeSpecialCard(true);
                        },
                      },
                      {
                        title: "Convert Noun",
                        icon: "edit",
                        action: () => {
                          cambiarCardDeFrase(chapterIndexSelected, index, i, {
                            available: false,
                            card: null,
                            character: `##${c?.character}##`,
                            suggestions: [],
                            type: "name",
                          });
                        },
                      },
                      {
                        title: "Delete card",
                        icon: "trash",
                        action: () => eliminarCardDeFrase(chapterIndexSelected, index, i),
                      },
                      {
                        title: "Add next card",
                        icon: "add",
                        action: () => {
                          setIsChangeCard(false);
                          setIndiceFraseSelected(index);
                          setIndiceCardSelected(i);
                          setIsModalAddCard(true);
                        },
                      },
                    ]}
                  />
                </div>
              ))}
              <Popover
                title="Suggested word"
                actionable={<span style={{ color: "#AEAEAE", fontWeight: 400, cursor: "pointer" }}>+ Add char</span>}
              >
                <div>
                  <TextInput
                    type="text"
                    required
                    placeholder="Search card..."
                    onChange={(e) => handleSearchCard(e.target.value)}
                  />
                  {cards?.value?.map((c, i) => (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #EFEFEF",
                        paddingBottom: 15,
                        paddingTop: 15,
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "start" }}>
                        <div
                          style={{
                            background: "#F1F1F1",
                            fontWeight: 400,
                            fontSize: "18px",
                            borderRadius: "8px",
                            width: "56px",
                            height: "56px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {c?.meaning?.character}
                        </div>
                        <div style={{ marginLeft: 20, width: "200px" }}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <b>{c?.meaning?.pinyin}</b>
                            <span style={{ marginLeft: 10, color: "#9A9FA5" }}>{c?.meaning?.pinyinTone}</span>
                          </div>
                          <div style={{ color: "#6F767E" }}>{c?.meaning?.trans}</div>
                        </div>
                      </div>
                      <span
                        onClick={() =>
                          anadirCardAFrase(chapterIndexSelected, index, {
                            available: true,
                            card: c,
                            character: c?.meaning?.character,
                            suggestions: [],
                          })
                        }
                        style={{ color: "#2A85FF", fontSize: "16px", letterSpacing: "-1%", cursor: "pointer" }}
                      >
                        + Add
                      </span>
                    </div>
                  ))}
                </div>
              </Popover>
            </div>
          ))}
          <div
            onClick={handleAddNewSentence}
            style={{ color: "#2A85FF", fontSize: "16px", letterSpacing: "-1%", cursor: "pointer" }}
          >
            + Add new sentence
          </div>
        </div>
      </Card>
    </>
  );
};

export default Chinese;

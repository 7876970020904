import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { createClasse, deleteClasse, getClasse, getClasses, updateClasse } from "../../api/classes.service";
import { copyOf } from "../../utils";

export const fetchClasses = createAsyncThunk("classes/fetchClasses", async (data, { rejectWithValue }) => {
  const classes = await getClasses();
  console.log("****+classes -> ", classes);
  return classes.data;
});

const fetchClassesReducer = {
  [fetchClasses.pending]: (state, action) => {
    state.isLoading = true;
    state.error = null;
  },
  [fetchClasses.fulfilled]: (state, action) => {
    const classes = action.payload.results;
    if (classes && Array.isArray(classes)) {
      state.value = classes;
      // state.value.sort((a, b) => {
      //   return !a.stat.lastPlayed
      //     ? 1
      //     : !b.stat.lastPlayed
      //     ? -1
      //     : a.stat.lastPlayed < b.stat.lastPlayed
      //     ? 1
      //     : a.stat.lastPlayed > b.stat.lastPlayed
      //     ? -1
      //     : 0;
      // });
    }

    state.error = null;
    state.isLoading = false;
  },
  [fetchClasses.rejected]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const fetchEditClasse = createAsyncThunk("classes/fetchEditClasse", async (data, { rejectWithValue }) => {
  const classes = await getClasse(data);
  console.log("****+classes -> ", classes);
  return classes.data;
});

const fetchEditClasseReducer = {
  [fetchEditClasse.pending]: (state, action) => {
    state.isLoading = true;
    state.error = null;
    state.editClasseValue = null;
  },
  [fetchEditClasse.fulfilled]: (state, action) => {
    const classe = action.payload;
    state.editClasseValue = classe;
    state.error = null;
    state.isLoading = false;
  },
  [fetchEditClasse.rejected]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const fetchCreateClasse = createAsyncThunk("classes/fetchCreateClasse", async (data, { rejectWithValue }) => {
  console.log("fetchCreateClasse --> ", data);
  const classes = await createClasse(data);
  console.log("****+classes -> ", classes);
  return classes.data;
});

const fetchCreateClasseReducer = {
  [fetchCreateClasse.pending]: (state, action) => {
    state.isLoadingCreate = true;
    state.isLoading = true;
    state.error = null;
  },
  [fetchCreateClasse.fulfilled]: (state, action) => {
    const respData = action.payload;
    state.value.push(respData);
    state.error = null;
    state.editClasseValue = null;
    state.isLoadingCreate = false;
    state.isLoading = false;
  },
  [fetchCreateClasse.rejected]: (state, action) => {
    state.isLoadingCreate = false;
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const fetchUpdateClasse = createAsyncThunk("classes/fetchUpdateClasse", async (data, { rejectWithValue }) => {
  const classe = await updateClasse(data);
  console.log("****+classes -> ", classe);
  return classe.data;
});

const fetchUpdateClasseReducer = {
  [fetchUpdateClasse.pending]: (state, action) => {
    state.isLoading = true;
    state.isLoadingCreate = true;
    state.error = null;
  },
  [fetchUpdateClasse.fulfilled]: (state, action) => {
    const respData = action.payload;
    const currentValue = copyOf(current(state.value)).filter((v) => v._id !== respData._id);
    state.value = currentValue;
    state.value.unshift(respData);
    state.error = null;
    state.isLoading = false;
    state.isLoadingCreate = false;
    state.editClasseValue = null;
  },
  [fetchUpdateClasse.rejected]: (state, action) => {
    state.isLoading = false;
    state.isLoadingCreate = false;
    state.error = action.payload;
  },
};

export const fetchDeleteClasse = createAsyncThunk("classes/fetchDeleteClasse", async (data, { rejectWithValue }) => {
  const classe = await deleteClasse(data);
  console.log("****+classes -> ", classe);
  return data;
});

const fetchDeleteClasseReducer = {
  [fetchDeleteClasse.pending]: (state, action) => {
    state.isLoading = true;
    state.isLoadingCreate = true;
    state.error = null;
  },
  [fetchDeleteClasse.fulfilled]: (state, action) => {
    const respData = action.payload;
    const currentValue = copyOf(current(state.value)).filter((v) => v._id !== respData._id);
    state.value = currentValue;
    state.error = null;
    state.isLoading = false;
    state.isLoadingCreate = false;
    state.editClasseValue = null;
  },
  [fetchDeleteClasse.rejected]: (state, action) => {
    state.isLoading = false;
    state.isLoadingCreate = false;
    state.error = action.payload;
  },
};

export const classesSlice = createSlice({
  name: "classes",
  initialState: {
    value: [],
    error: null,
    editClasseValue: null,
    isLoading: false,
    isLoadingCreate: false,
  },
  reducers: {
    cleanClasses: (state, action) => {
      state.value = [];
    },
  },
  extraReducers: {
    ...fetchClassesReducer,
    ...fetchCreateClasseReducer,
    ...fetchUpdateClasseReducer,
    ...fetchEditClasseReducer,
    ...fetchDeleteClasseReducer,
  },
});

export const { cleanClasses } = classesSlice.actions;

export default classesSlice.reducer;

import React, { useState } from "react";
import cn from "classnames";
import styles from "./DecksImage.module.sass";
import Loader from "../../components/Loader";

// data
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import { fetchDecks, fetchDeleteDeck } from "../../app/decks/decks.slice";
import { useEffect } from "react";
import TableComponent from "../../components/TableComponent";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'
import Control from "../../components/Table/Row/Control";
import Switch from "../../components/Switch";
import { getDeckCSV } from "../../api/decks.service";
import { toast } from "sonner";

dayjs.extend(relativeTime)

const sorting = ["list", "grid"];

const columns = [
  { 
    header: 'Title',
    accessor: 'title'
  },
  { 
    header: 'Mini',
    accessor: 'mini'
  },
  { 
    header: 'Small',
    accessor: 'small'
  },
  { 
    header: 'Normal',
    accessor: 'normal'
  },
  { 
    header: 'Medium',
    accessor: 'medium'
  },
]

const DecksImages = () => {
  const { decks, category } = useSelector((state) => state);
  const [activeIndex, setActiveIndex] = useState(0);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [search, setSearch] = useState("");

  const [dataTable, setDataTable] = useState([])

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    alert();
  };

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  const handleDeleteSelect = (item) => {
    setModalDeleteOpen(true);
    setSelectedItem(item);
  };

  const handleDelete = () => {
    dispatch(fetchDeleteDeck(selectedItem));
    setModalDeleteOpen(false);
    setSelectedItem(null);
  };

  const handleDownloadCSV = async (item) =>{
    console.log(item)

    const data = await getDeckCSV(item)
    console.log("data", data)

    if(data?.data){
      var buffer = new ArrayBuffer(3);
      var dataView = new DataView(buffer);
      dataView.setUint8(0, 0xfe);
      dataView.setUint8(1, 0xbb);
      dataView.setUint8(2, 0xbf);
      var read = new Uint8Array(buffer)
  
      var blob = new Blob([`\ufeff${data?.data}`], {type: 'text/csv;charset=utf-8'});
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = `${item?.key}.csv`;
      document.body.appendChild(a);
      a.click();
    }else{
      toast.error(data?.response?.data?.message)
    }

  }

  useEffect(() => {
    dispatch(fetchDecks())
  }, [])

  useEffect(() => {
    console.log("category", category)
  }, [category])
  

  useEffect(() => {
    if(decks){
      console.log("decks", decks?.value)
      setDataTable(decks?.value?.map(item=>{
        return{
            title: {
                data: item.title ? item.title : item.key,
                dataComponent: (<div style={{width: '100px'}}>{item.title ? item.title : item.key}</div>)
            },
            mini: {
                data: 'image',
                dataComponent: (
                <div style={{height: '50px', width: '50px'}}>
                    <img
                    src={(!item.avatar?.miniUrl?.includes('undefined') && item?.avatar?.miniUrl) ? item.avatar?.miniUrl : "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"}
                    alt="Product"
                    style={{height: '40px', width: '40px'}}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src="https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png";
                    }}
                    />
                </div>
                )
            },
            small: {
                data: 'image',
                dataComponent: (
                <div style={{height: '100px', width: '100px'}}>
                    <img
                    src={(!item.avatar?.smallUrl?.includes('undefined') && item?.avatar?.smallUrl) ? item.avatar?.smallUrl : "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"}
                    alt="Product"
                    style={{height: '80px', width: '80px'}}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src="https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png";
                    }}
                    />
                </div>
                )
            },
            normal: {
                data: 'image',
                dataComponent: (
                <div style={{height: '150px', width: '150px'}}>
                    <img
                    src={(!item.avatar?.normalUrl?.includes('undefined') && item?.avatar?.normalUrl) ? item.avatar?.normalUrl : "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"}
                    alt="Product"
                    style={{height: '130px', width: '130px'}}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src="https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png";
                    }}
                    />
                </div>
                )
            },
            medium: {
                data: 'image',
                dataComponent: (
                <div style={{height: '200px', width: '200px'}}>
                    <img
                    src={(!item.avatar?.mediumUrl?.includes('undefined') && item?.avatar?.mediumUrl) ? item.avatar?.mediumUrl : "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"}
                    alt="Product"
                    style={{height: '180px', width: '180px'}}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src="https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png";
                    }}
                    />
                </div>
                )
            },
        }
      }))
    }
  }, [decks])
  
  

  return (
    <>
        <div className={styles.wrapper}>
          {decks.isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader className={styles.loader} />
            </div>
          ) : decks.value.length === 0 ? (
            <div className={styles.loaderContainer}>No decks created yet</div>
          ) : (
            // <Table items={decks.value?.filter(d => d?._id)} title="Last edited" onDelete={handleDeleteSelect} />
            <TableComponent
                data={dataTable}
                columns={columns}
                title={`Decks`}
                searchPlaceholder="Search deck..."
                className="w-full"
            />
          )}
        </div>
      {/* <Modal
        visible={modalDeleteOpen}
        onClose={() => {
          setSelectedItem(null);
          setModalDeleteOpen(false);
        }}
      >
        <div style={{ margin: "48px 6px", width: "100%", fontSize: 16 }}>Are you sure you want to delete the deck?</div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            className={cn("button-stroke button-small", styles.button)}
            style={{ marginRight: 10 }}
            onClick={() => {
              setSelectedItem(null);
              setModalDeleteOpen(false);
            }}
          >
            <span>Cancel</span>
          </button>
          <button className={cn("button-stroke-red button-small", styles.button)} onClick={handleDelete}>
            <span>Delete</span>
          </button>
        </div>
      </Modal> */}
    </>
  );
};

export default DecksImages;

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import styles from "./ReplyFeedback.module.sass";
import Modal from '../../../components/Modal';
import { EditorState, convertToRaw, ConvertFromRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Card from '../../../components/Card';
import Switch from '../../../components/Switch';
import Editor from '../../../components/Editor';
import classNames from 'classnames';
import { createNews } from '../../../api/news.service';
import { toast } from 'sonner';
import { fetchFeedback } from '../../../app/feedback/feedback.slice';
import { updateFeedback } from '../../../api/feedback.service';

const ReplyFeedback = ({ visible, setVisible, selectedBug }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { bugs, cards } = useSelector((state) => state);

    const [message, setMessage] = useState('')

    console.log("selectedBug", selectedBug)

    const [objectTextarea, setObjectTextarea] = useState({
        state: EditorState.createEmpty(),
        trans: ""
    })

    const handleReply = async () =>{
        let json = {
            type: "InApp",
            mailTemplate: "News",
            photoUrl: "",
            title: [{
                active: true,
                lang: 'en',
                trans: 'Response to feedback: '
            }],
            textArea: [{
                active: true,
                lang: 'en',
                trans: `Feedback Type: ${selectedBug?.feedbackType} <br /> Content: ${selectedBug?.content} <br /> Response: ${objectTextarea.trans}`
            }],
            usersObjective: [selectedBug?.createdBy?._id],
            scheduled: new Date(),
            active: false,
            linkUrl: "",
            linkTitle: ""
        }

        let data = await createNews(json)
        let feedbackUpdate = updateFeedback({_id: selectedBug?._id, active: false})
        console.log("json", json)
        console.log("data", data)
        console.log("feedbackUpdate", feedbackUpdate)
        if(data?.data?._id){
            toast.success('Reply sent!')
            dispatch(fetchFeedback());
            setVisible(false)
            setObjectTextarea({
                state: EditorState.createEmpty(),
                trans: ""
            })
        }else{
            toast.error(data?.data?.message)
        }
        
    }

    return (
        <Modal outerClassName={styles.outer} visible={visible} onClose={() => setVisible(false)} >
            <Card title={`Reply to @${selectedBug?.createdBy?.name}`} classTitle="title-red">
                <div>
                    <div>
                        <div style={{fontStyle: 'italic'}}>
                            {selectedBug?.createdBy?.name}
                        </div>
                        <div style={{background: '#f5f5f5', padding: 10, borderTopRightRadius: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10}}>
                            {selectedBug?.content}
                        </div>
                    </div>
                    <div style={{ marginTop: 25 }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <label style={{fontSize: 14}}>
                                Reply
                            </label>
                        </div>
                        <div style={{marginTop: 10}}>    
                            <Editor
                                onContentStateChange={setMessage}
                                state={objectTextarea?.state}
                                onChange={(e)=>setObjectTextarea(objectTextarea => {return{ state: e, trans: draftToHtml(convertToRaw(e?.getCurrentContent())) }})}
                                classEditor={styles.editor}
                            />
                        </div>
                    </div>

                    <div style={{ marginTop: 25, display: 'flex', justifyContent: 'flex-end' }}>
                        <button onClick={handleReply} className={classNames("button", styles.button)} >
                            <span>Reply</span>
                        </button>
                    </div>
                </div>
            </Card>
        </Modal>
    );
}

export default ReplyFeedback;
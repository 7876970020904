import React, { useState } from "react";
import cn from "classnames";
import styles from "./Classes.module.sass";
import Card from "../../components/Card";
import Form from "../../components/Form";
import Icon from "../../components/Icon";
import Table from "../../components/Table";
import Product from "../../components/Product";
import Loader from "../../components/Loader";

// data
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import { fetchDeleteClasse } from "../../app/classes/classes.slice";
import TableClasses from "../../components/TableClasses";

const sorting = ["list", "grid"];

const Classes = () => {
  const { classes } = useSelector((state) => state);
  const [activeIndex, setActiveIndex] = useState(0);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    alert();
  };

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  const handleDeleteSelect = (item) => {
    setModalDeleteOpen(true);
    setSelectedItem(item);
  };

  const handleDelete = () => {
    dispatch(fetchDeleteClasse(selectedItem));
    setModalDeleteOpen(false);
    setSelectedItem(null);
  };

  const handleEdit = () => {};

  return (
    <>
      <Card
        className={styles.card}
        classCardHead={styles.head}
        title="Classes"
        classTitle={cn("title-purple", styles.title)}
        head={
          <>
            {/* <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              onSubmit={() => handleSubmit()}
              placeholder="Search classes"
              type="text"
              name="search"
              icon="search"
            /> */}
            {/* <div className={styles.sorting}>
              {sorting.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  <Icon name={x} size="24" />
                </button>
              ))} 
            </div> */}
          </>
        }
      >
        <div className={styles.wrapper}>
          {classes.isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader className={styles.loader} />
            </div>
          ) : classes.value.length === 0 ? (
            <div className={styles.loaderContainer}>No classes created yet</div>
          ) : (
            <TableClasses items={classes.value} title="Last edited" onDelete={handleDeleteSelect} onEdit={handleEdit} />
          )}
          {/* {activeIndex === 1 && (
            <>
              <div className={styles.list}<>
                {classes.value.map((x, index) => (
                  <Product
                    className={styles.product}
                    value={selectedFilters.includes(x.id)}
                    onChange={() => handleChange(x.id)}
                    item={x}
                    key={index}
                    released
                  />
                ))}
              </div>
              <div className={styles.foot}>
                <button className={cn("button-stroke button-small", styles.button)}>
                  <Loader className={styles.loader} />
                  <span>Load more</span>
                </button>
              </div>
            </>
          )} */}
        </div>
      </Card>
      <Modal
        visible={modalDeleteOpen}
        onClose={() => {
          setSelectedItem(null);
          setModalDeleteOpen(false);
        }}
      >
        <div style={{ margin: "48px 6px", width: "100%", fontSize: 16 }}>Are you sure you want to delete the deck?</div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            className={cn("button-stroke button-small", styles.button)}
            style={{ marginRight: 10 }}
            onClick={() => {
              setSelectedItem(null);
              setModalDeleteOpen(false);
            }}
          >
            <span>Cancel</span>
          </button>
          <button className={cn("button-stroke-red button-small", styles.button)} onClick={handleDelete}>
            <span>Delete</span>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Classes;

import React, { useEffect, useState } from 'react'
import styles from "./ReplyFeedback.module.sass";
import Modal from '../../../components/Modal';
import Card from '../../../components/Card';
import classNames from 'classnames';

const DeleteFeedbackModal = ({ visible, setVisible, selectedBug, handleDeleteBug }) => {
    return (
        <Modal outerClassName={styles.outer} visible={visible} onClose={() => setVisible(false)} >
            <Card title={`Delete feedback @${selectedBug?.createdBy?.name}`} classTitle="title-red">
                <div>
                    <div>
                        <div style={{fontStyle: 'italic'}}>
                            {selectedBug?.createdBy?.name}
                        </div>
                        <div style={{background: '#f5f5f5', padding: 10, borderTopRightRadius: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10}}>
                            {selectedBug?.content}
                        </div>
                    </div>

                    <div style={{ marginTop: 25, display: 'flex', justifyContent: 'flex-end' }}>
                        <button onClick={()=>handleDeleteBug(selectedBug)} style={{ backgroundColor: 'red'}} className={classNames("button", styles.button)} >
                            <span>Delete</span>
                        </button>
                    </div>
                </div>
            </Card>
        </Modal>
    );
}

export default DeleteFeedbackModal;
import React, { useState } from "react";
import styles from "./Image.module.sass";
import Card from "../../../../../components/Card";
import File from "../../../../../components/File";
import { useEffect } from "react";

const Image = ({ imageSelected, setImageSelected, isEdit }) => {
  const [fileURL, setFileURL] = useState(null);

  const handleUploadFile = (e) => {
    setImageSelected(e?.target.files[0]);
  };

  useEffect(() => {
    setFileURL(typeof imageSelected === "object" ? URL.createObjectURL(imageSelected) : imageSelected);
  }, [imageSelected]);

  return (
    <Card title="Image" classTitle="title-blue">
      <div>
        {fileURL ? (
          <div>
            <img width="100%" src={fileURL} />
            <button
              onClick={() => {
                setFileURL("");
                setImageSelected("");
              }}
            >
              Delete Image
            </button>
          </div>
        ) : (
          <File
            className={styles.field}
            onChange={handleUploadFile}
            title="Click or drop image"
            label="Upload video"
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
          />
        )}
      </div>
    </Card>
  );
};

export default Image;

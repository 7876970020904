import React, { useState } from "react";
import styles from "./Translate.module.sass";
import Card from "../../../../../components/Card";
import cn from "classnames";
import Actions from "../../../../../components/Actions";
import { useEffect } from "react";
import { uploadVideoSentence } from "../../../../../api/texts.service";
import Modal from "../../../../../components/Modal";
import { toast } from "sonner";

const LANGS = ["en", "es", "fr", "ru", "pt"];

const Translate = ({
  chapter,
  editarCapitulo,
  editarFraseDeCapitulo,
  anadirFraseACapitulo,
  anadirCardAFrase,
  editarTraduccionDeFrase,
  eliminarFraseDeCapitulo,
  eliminarCardDeFrase,
  chapterIndexSelected,
  handleGetContent,
  id,
  traducirTextoFrase,
  myRefs,
}) => {
  const [activeTitleTab, setActiveTitleTab] = useState("en");

  const [sentenceIndexSelected, setSentenceIndexSelected] = useState("");

  const [isModalUploadVideo, setIsModalUploadVideo] = useState(false);

  const [videoSrc, setVideoSrc] = useState(null);
  const [file, setFile] = useState("");

  const [loading, setLoading] = useState(false);

  console.log("chapterIndexSelected", chapterIndexSelected);

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file) {
      const src = URL.createObjectURL(file);
      setVideoSrc(src);
    }
  };

  const handleUploadVideoSentence = async () => {
    setLoading(true);
    let body = new FormData();
    body.append("textId", id);
    body.append("chapter", chapterIndexSelected);
    body.append("sentence", sentenceIndexSelected);
    body.append("file", file);
    const result = await uploadVideoSentence(body);
    setLoading(false);
    if (result?.data === true) {
      toast.success("Uploaded succesfully");
      setVideoSrc("");
      setFile("");
      setIsModalUploadVideo(false);
      //handlePutContent();
      handleGetContent();
    } else {
      toast.error("There was an error uploading this video.");
    }
  };

  console.log("chapter?.sentences --> ", chapter?.sentences);

  return (
    <>
      <Modal
        outerClassName={styles.outer}
        visible={isModalUploadVideo}
        onClose={() => setIsModalUploadVideo(false)}
        stylesCustom={{ maxWidth: 600 }}
      >
        <div>
          {videoSrc && <video src={videoSrc} controls width="500" />}
          {videoSrc ? (
            <button
              onClick={() => {
                setFile("");
                setVideoSrc("");
              }}
              style={{ backgroundColor: "red" }}
              className={cn("button-small", styles.button)}
            >
              Delete
            </button>
          ) : (
            <input type="file" accept="video/*" onChange={handleVideoChange} />
          )}
          <button
            disabled={!file || !videoSrc || loading}
            onClick={handleUploadVideoSentence}
            style={{ marginLeft: 20 }}
            className={cn("button-small", styles.button)}
          >
            {loading ? "Uploading..." : "Upload"}
          </button>
        </div>
      </Modal>
      <Card
        title="Translate"
        classTitle="title-blue"
        head={
          <div style={{ display: "flex", alignItems: "center" }} className={cn(styles.nav, "tablet-hide")}>
            {LANGS.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: x === activeTitleTab,
                })}
                onClick={() => setActiveTitleTab(x)}
                key={index}
              >
                {x?.toUpperCase()}
              </button>
            ))}
          </div>
        }
      >
        <div style={{ minHeight: "65vh" }}>
          {chapter?.sentences?.map((c, i) => {
            return (
              <div
                style={{
                  borderRadius: "12px",
                  height: myRefs?.current[i]?.offsetHeight || "auto",
                  backgroundColor: "#F4F4F4",
                  border: "2px solid lightgray",
                  padding: "16px",
                  position: "relative",
                  marginTop: i !== 0 ? 15 : 0,
                }}
              >
                <div style={{ width: "80%" }}>
                  <textarea
                    style={{ width: "100%", background: "transparent", border: "none" }}
                    rows="6"
                    value={c?.translations?.find((t) => t?.lang === activeTitleTab)?.trans || ""}
                    onChange={(e) =>
                      editarTraduccionDeFrase(chapterIndexSelected, i, activeTitleTab, {
                        active: activeTitleTab === "en" ? true : false,
                        lang: activeTitleTab,
                        trans: e.target.value,
                      })
                    }
                  />
                  {c?.videoUrl && <video controls style={{ maxHeight: 80, width: 240 }} src={c?.videoUrl} />}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 40,
                    right: 10,
                    color: !c?.translations?.find((t) => t?.lang === activeTitleTab)?.active ? "red" : "green",
                  }}
                >
                  {c?.translations?.find((t) => t?.lang === activeTitleTab)?.active ? "Verified" : "Not Verified"}
                </div>
                <div style={{ position: "absolute", top: 10, right: 10 }}>
                  <Actions
                    className={styles.actions}
                    classActionsHead={styles.actionsHead}
                    items={[
                      {
                        title: "Auto-translate",
                        icon: "check",
                        action: () =>
                          traducirTextoFrase(
                            chapterIndexSelected,
                            i,
                            c?.translations?.find((t) => t?.lang === "en")?.trans,
                            activeTitleTab
                          ),
                      },
                      {
                        title: "Verify Trans",
                        icon: "check-all",
                        action: () =>
                          editarTraduccionDeFrase(chapterIndexSelected, i, activeTitleTab, {
                            active: true,
                            lang: activeTitleTab,
                            trans: c?.translations?.find((t) => t?.lang === activeTitleTab)?.trans,
                          }),
                      },
                      {
                        title: "Upload Video",
                        icon: "upload",
                        action: () => {
                          setSentenceIndexSelected(i);
                          setIsModalUploadVideo(true);
                        },
                      },
                      {
                        title: <span style={{ opacity: c?.videoUrl ? 1 : 0.5 }}>Video</span>,
                        icon: "download",
                        action: () => {
                          if (c?.videoUrl) {
                            console.log("tiene video");
                          }
                        },
                      },
                      {
                        title: <span style={{ opacity: c?.audioUrl ? 1 : 0.5 }}>Audio</span>,
                        icon: "download",
                        action: () => {
                          if (c?.audioUrl) {
                            console.log("tiene audio");
                          }
                        },
                      },
                      {
                        title: "Delete",
                        icon: "trash",
                        action: () => eliminarFraseDeCapitulo(chapterIndexSelected, i),
                      },
                    ]}
                    small
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Card>
    </>
  );
};

export default Translate;

import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./IncludedCards.module.sass";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Tooltip from "../../../components/Tooltip";
import Checkbox from "../../../components/Checkbox";
import { WithContext as ReactTags } from "react-tag-input";
import DropdownSearch from "../../../components/DropdownSearch";
import { useDispatch, useSelector } from "react-redux";
import { fetchCards } from "../../../app/cards/cards.slice";
import SelectedTags from "../../../components/SelectedTags";
import { current } from "@reduxjs/toolkit";
import { parseSearchCardTags } from "../../../utils";
import TableComponent from "../../../components/TableComponent";
import { useHistory } from "react-router";

const categoryOptions = ["Study", "Business", "Alf"];

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const columns = [
  {
    header: 'Char',
    accessor: 'char'
  },
  {
    header: 'Pinyin',
    accessor: 'pinyin'
  },
  {
    header: 'Translation',
    accessor: 'translation'
  },
  {
    header: 'Actions',
    accessor: 'actions'
  },
]

const IncludedCards = ({ className, selectedCards, setSelectedCards, deckId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, cards } = useSelector((state) => state);

  const [data, setData] = useState([])

  //TODO REMOVE

  console.log("cars -> ", cards)

  const [tableCards, setTableCards] = useState([])

  useEffect(() => {
    if (user.value) {
      dispatch(
        fetchCards({
          take: 100,
          skip: 0,
          //search: "a",
        })
      );
    }
  }, [user.value]);

  const handleSearch = (term) => {
    dispatch(
      fetchCards({
        take: 100,
        skip: 0,
        search: term,
      })
    );
  };

  const handleAddCard = (value) => {
    if(!selectedCards.find(element => element.value === value.value)){
      setSelectedCards((currentVal) => [...currentVal, value]);
    }
  };

  const handleRemoveCard = (rCard) => {
    setSelectedCards((currentVal) => currentVal.filter((c) => c.value !== rCard.value));
  };


  useEffect(() => {
    console.log("selectedCards", selectedCards)
    setData(selectedCards.map((card)=>{
      return{
        char: {
          data: card?.character,
          dataComponent: card?.character
        },
        pinyin: {
          data: card?.pinyin,
          dataComponent: card?.pinyin
        },
        translation: {
          data: card?.translation,
          dataComponent: card?.translation
        },
        actions: {
          data: 'actions',
          dataComponent: <div style={{ display: 'flex', alignItems: 'center' }}>
            <button onClick={()=>handleRemoveCard(card)} style={{color: 'red'}}>
              Delete
            </button>
            <button onClick={()=>history.push(`/card/edit/${card?.value}?deckId=${deckId}`)} style={{color: 'blue', marginLeft: 20}}>
              Edit
            </button>
          </div>
        },
      }
    }))
  }, [selectedCards])


  useEffect(() => {

    let dataFiltered = []



    setTableCards()
  }, [data])
  
  

  return (
    <Card className={cn(styles.card, className)} title="Included cards" classTitle="title-blue">
      <div className={styles.images}>
        {/* <Dropdown
          className={styles.field}
          label="Category"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          value={category}
          setValue={setCategory}
          options={optionsCategory}
        /> */}
        <div className={styles.label}>
          Select cards that are included in this deck{" "}
          <Tooltip
            className={styles.tooltip}
            title="Maximum 100 characters. No HTML or emoji allowed"
            icon="info"
            place="right"
          />
        </div>
        <div style={{ marginTop: 12 }}>
          <DropdownSearch
            className={styles.field}
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            value={"yep"}
            setValue={handleAddCard}
            options={cards.value.map((card) => parseSearchCardTags(card))}
            isLoading={cards.isLoading}
            timeoutSearch={500}
            onSearch={handleSearch}
          />
        </div>
        <div style={{ marginTop: 12 }}>
          <TableComponent 
            title="Selected Cards"
            isCardStyle={false}
            columns={columns}
            paginate={20}
            data={data}
          />
        </div>
        {/* <div style={{ marginTop: 12 }}>
          {selectedCards.length === 0 ? (
            "No cards selected yet"
          ) : (
            <SelectedTags
              tags={selectedCards.map((el, index) => ({
                value: el.value,
                id: `${index}`,
                title: `${el.character} - ${el.label}`,
              }))}
              onTagRemove={handleRemoveCard}
            />
          )}
        </div> */}
      </div>
    </Card>
  );
};

export default IncludedCards;

import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Feedback.module.sass";
import { toast } from 'sonner'
import TableComponent from "../../components/TableComponent";
import { BiPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { updateBug } from "../../api/bug.service";
import { fetchBug } from "../../app/bugs/bugs.slice";
import dayjs from "dayjs";
import ReplyFeedback from "./components/ReplyFeedback";
import { deleteFeedback, updateFeedback } from "../../api/feedback.service";
import { fetchFeedback } from "../../app/feedback/feedback.slice";
import DeleteFeedbackModal from "./components/DeleteFeedback";

const Feedback = () => {

    const [visibleModalReplyBug, setVisibleModalReplyBug] = useState(false)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)

    const [selectedBug, setSelectedBug] = useState('')

    const dispatch = useDispatch();

    const { bugs, feedback, user } = useSelector((state) => state)

    const [dataTable, setDataTable] = useState([])

    console.log("feedback", feedback)

    const handleMouseEnter = (e, i) =>{
        try{
            document.getElementById(`card_tooltip__${i}`).style.display = 'block'
        }catch (err){
            console.log(err)
        }
    }

    const handleMouseLeave = (e, i) =>{
        try{
            document.getElementById(`card_tooltip__${i}`).style.display = 'none'
        }catch (err){
            console.log(err)
        }
    }


    const handleDeleteBug = async (bug) =>{

      let json = {
          _id: bug?._id,
          active: false
      }

      const resp = await deleteFeedback(json)
      console.log("resp", resp)
      if(resp?.status === 200){
        toast.success('Feedback deleted')
        setVisibleModalDelete(false)
        dispatch(fetchFeedback());
      }else{
        toast.error(`Feedback not deleted (${resp?.response?.data?.message})`)
      }
      
    }

    useEffect(() => {
      console.log("feedback", feedback)
      if(feedback?.value?.length > 0){
        let feedbackArray = feedback?.value

        setDataTable(feedbackArray?.map((bug, index)=>{
          return{
            type: {
              data: bug?.feedbackType,
              dataComponent: <p title={bug?.feedbackType} class={styles.w20}>{bug?.feedbackType}</p> 
            },
            reason: {
              data: bug?.content,
              dataComponent: <p title={bug?.content} class={styles.w40}>{bug?.content}</p> 
            },
            report_by: {
              data: bug?.createdBy?.name,
              dataComponent: (
                <div onMouseEnter={(e)=>handleMouseEnter(e, bug?._id)} onMouseLeave={(e)=>handleMouseLeave(e, bug?._id)} style={{position: 'relative'}} className="">
                  <div id={`card_tooltip__${bug?._id}`} style={{position: 'absolute', display: 'none', top:  0, transform: index === 0 ? '' : 'translateY(-100%)', width: 'auto', minWidth: '200px', boxShadow: '0px 6px 25px -8px rgba(186,186,186,1)', zIndex: 9999, left: 0, backgroundColor: 'white', padding: 12, borderRadius: 8}}>
                    <div style={{display: 'flex', fontSize: 14, alignItems: 'center', justifyContent: 'space-between'}}>
                        <div style={{color: 'gray'}}>
                            Level
                        </div>
                        <div style={{color: 'black'}}>
                            {bug?.createdBy?.onboard?.level || '-'}
                        </div>
                    </div>
                    <div style={{display: 'flex', fontSize: 14, alignItems: 'center', justifyContent: 'space-between'}}>
                        <div style={{color: 'gray'}}>
                            Time Played
                        </div>
                        <div style={{color: 'black'}}>
                            {bug?.createdBy?.stats?.totalTime || '-'}
                        </div>
                    </div>
                    <div style={{display: 'flex', fontSize: 14, alignItems: 'center', justifyContent: 'space-between'}}>
                        <div style={{color: 'gray'}}>
                            Know Cards
                        </div>
                        <div style={{color: 'black'}}>
                            {bug?.createdBy?.stats?.knowCards || '-'}
                        </div>
                    </div>
                  </div>
                  <div>
                    {bug?.createdBy?.name}
                  </div>
                </div>
              )
            },
            status: {
              data: bug?.active,
              dataComponent: bug?.active ? (
                <span style={{color: '#EC7D5A'}}>
                  Pending
                </span>
              ) : (
                <span style={{ color: 'green'}}>
                  Answered
                </span>
              )
            },
            date: {
              data: bug?.date,
              dataComponent: <span>{dayjs(bug?.date).format('DD MMM YYYY, HH:mm')}</span>
            },
            actions:{
              data: "actions",
              dataComponent: <div style={{ display: "flex", alignItems: "center"}}>
                
                  <button style={{color: 'black'}} onClick={()=>{
                    setVisibleModalReplyBug(true)
                    setSelectedBug(bug)
                  }}>
                      Reply
                  </button>
                  <button style={{color: 'red', marginLeft: 20}} onClick={()=>{
                    setVisibleModalDelete(true)
                    setSelectedBug(bug)
                  }} >
                      Delete
                  </button>
              </div>
            }
          }
        }))
      }
    }, [feedback])
    

    let columns = [
      {
        header: 'Reason',
        accessor: 'reason'
      },
      {
        header: 'Report By',
        accessor: 'report_by'
      },
      {
        header: 'Type',
        width: 40,
        accessor: 'type'
      },
      {
        header: 'Status',
        accessor: 'status'
      },
      {
        header: "Date",
        accessor: 'date'
      },
      {
        header: 'Action',
        accessor: 'actions'
      },
    ]
    

    return (
      <>
        <DeleteFeedbackModal visible={visibleModalDelete} setVisible={setVisibleModalDelete} selectedBug={selectedBug} handleDeleteBug={handleDeleteBug} />
        <ReplyFeedback visible={visibleModalReplyBug} setVisible={setVisibleModalReplyBug} selectedBug={selectedBug} />
        <TableComponent 
            title="Feedback"
            columns={columns}
            data={dataTable}
            isFilter
            filterAccessor="lang"
            searchPlaceholder="Search Feedback..."
        />
      </>
    );
};

export default Feedback;

import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./IncludedStudents.module.sass";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Tooltip from "../../../components/Tooltip";
import Checkbox from "../../../components/Checkbox";
import { WithContext as ReactTags } from "react-tag-input";
import DropdownSearch from "../../../components/DropdownSearch";
import { useDispatch, useSelector } from "react-redux";
import { fetchCards } from "../../../app/cards/cards.slice";
import SelectedTags from "../../../components/SelectedTags";
import { current } from "@reduxjs/toolkit";
import { parseSearchCardTags } from "../../../utils";
import TextInput from "../../../components/TextInput";
import useKeyPress from "../../../hooks/useKeyPress";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";

const IncludedCards = ({ className, selectedStudents, setSelectedStudents, title, setTitle }) => {
  const [currentEmail, setCurrentEmail] = useState("");
  const [isFocusedInput, setIsFocusedInput] = useState(false);

  const handleAddEmail = () => {
    if (isFocusedInput) {
      setCurrentEmail("");
      setSelectedStudents((currentVal) => [...currentVal, currentEmail]);
    }
  };

  const handleRemoveStudent = (rCard) => {
    setSelectedStudents((currentVal) => currentVal.filter((c) => c !== rCard.value));
  };

  useKeyPress("Enter", handleAddEmail, [currentEmail, isFocusedInput]);

  return (
    <Card
      className={cn(styles.card, className)}
      title="Included students"
      classTitle="title-blue"
      head={
        <Link className={cn("button-stroke button-small", styles.button)} to="/classes">
          <Icon name="arrow-left" size="24" />
          <span>Back</span>
        </Link>
      }
    >
      <TextInput
        className={styles.field}
        label="Class title"
        name="title"
        type="text"
        tooltip="Maximum 100 characters. No HTML or emoji allowed"
        onChange={(ev) => setTitle(ev.target.value)}
        value={title}
        required
      />
      <div className={styles.images}>
        {/* <Dropdown
          className={styles.field}
          label="Category"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          value={category}
          setValue={setCategory}
          options={optionsCategory}
        /> */}
        <div className={styles.label}>
          Write the emails of the students and press enter to include them.{" "}
          <Tooltip
            className={styles.tooltip}
            title="Maximum 100 characters. No HTML or emoji allowed"
            icon="info"
            place="right"
          />
        </div>
        <div style={{ marginTop: 12 }}>
          <TextInput
            className={styles.field}
            label="Email"
            name="title"
            type="text"
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            onChange={(ev) => setCurrentEmail(ev.target.value)}
            value={currentEmail}
            onFocus={() => setIsFocusedInput(true)}
            onBlur={() => setIsFocusedInput(false)}
            required
          />
        </div>
        <div style={{ marginTop: 12 }}>
          {selectedStudents.length === 0 ? (
            "No included emails yet"
          ) : (
            <SelectedTags
              tags={selectedStudents.map((el, index) => ({
                value: el,
                id: `${index}`,
                title: el,
              }))}
              onTagRemove={handleRemoveStudent}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default IncludedCards;

import { deleteApi, getApi, postApi, putApi } from "./general.service";

export const getCards = async (data) => {
  const cards = getApi(
    `/admin/card?take=${data.take}&page=${data.skip}${data.deck !== undefined ? `&deck=${data.deck}` : ""}${data.deckId !== undefined ? `&deckId=${data.deckId}` : ""}${
      data.search ? `&search=${data.search}` : ""
    }${data.categoryId ? `&categoryId=${data.categoryId}` : ""}${data.show ? `&show=${data.show}` : ""}${
      data.played ? `&played=${data.played}` : ""
    }${data.score ? `&score=${data.score}` : ""}${data.order ? `&order=${data.order}` : ""}${data.order ? `&order=${data.order}` : ""}${data.nextPageHash ? `&nextPageHash=${data.nextPageHash}` : ''}`
  );
  return cards;
};

export const getCard = async (data) => {
  const resp = getApi(`/admin/card/${data._id}${data?.deck ? `?deck=${data.deck}` : ''}`, data);
  return resp;
};

export const updateCard = async (data) => {
  const resp = putApi("/admin/card", data);
  return resp;
};

export const createCard = async (data) => {
  const resp = postApi("/admin/card", data);
  return resp;
};

export const uploadVideosCard = async (data) => {
  const resp = postApi("/admin/card/videos", data, { headers: { 'Content-Type': 'multipart/form-data'}});
  return resp;
};

export const uploadBulkCard = async (data) => {
  const resp = postApi("/admin/card/csv", data, { headers: { 'Content-Type': 'multipart/form-data'}});
  return resp;
};


export const uploadVideoCard = async (data) => {
  const resp = postApi("/admin/card/media", data, { headers: { 'Content-Type': 'multipart/form-data'}});
  return resp;
};

export const editCardInfo = async (data) => {
  const resp = putApi("/admin/card/info", data);
  return resp;
};

export const addMyBrain = async (data) => {
  const resp = postApi(`/admin/card/brain/${data.cardId}`, {});
  console.log("fetchAddToMyBrain --> ", resp);
  return resp;
};

export const addDeckMyBrain = async (data) => {
  const resp = postApi(`/deck/brain/${data.deckId}`, {});
  return resp;
};

export const removeMyBrain = async (data) => {
  const resp = deleteApi(`/admin/card/brain/${data.cardId}`);
  return resp;
};

export const removeCard = async (cardId) => {
  const resp = deleteApi(`/admin/card?_id=${cardId}`);
  return resp;
};

export const removeDeckMyBrain = async (data) => {
  const resp = deleteApi(`/deck/brain/${data.deckId}`);
  return resp;
};

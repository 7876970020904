import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { postLogin } from "../../api/auth.service";

export const fetchLoginUser = createAsyncThunk("user/fetchLoginUser", async (data, { rejectWithValue }) => {
  const userRaw = localStorage.getItem("user");

  let user = null;
  if (userRaw) {
    user = JSON.parse(userRaw);
  } else if (data) {
    const resp = await postLogin(data);
    console.log("resp --> ", resp);
    if(resp?.data?.role === 'USER'){
      window.open('/', '_self')
    }else{
      user = resp.data;
    }
  }
  return {
    user,
  };
});

const fetchLoginUserReducer = {
  [fetchLoginUser.pending]: (state, action) => {
    state.isLoadingLogin = true;
    state.error = null;
  },
  [fetchLoginUser.fulfilled]: (state, action) => {
    const userData = action.payload.user;
    if (userData) {
      localStorage.setItem("user", JSON.stringify(userData));
      axios.defaults.headers.common = {
        Authorization: `bearer ${userData.token}`,
      };
      state.value = userData;
    }
    state.error = null;
    state.isLoadingLogin = false;
  },
  [fetchLoginUser.rejected]: (state, action) => {
    state.isLoadingLogin = false;
    state.error = action.payload;
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: null,
    isLoadingLogin: false,
  },
  reducers: {
    logOut: (state, action) => {
      state.value = null;
      localStorage.clear();
      window.open('/', "_self")
    },
  },
  extraReducers: {
    ...fetchLoginUserReducer,
  },
});

export const { logOut } = userSlice.actions;

export default userSlice.reducer;

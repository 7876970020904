import React, { useEffect, useState } from 'react'
import cn from "classnames";
import styles from "./Texts.module.sass";
import TableComponent from '../../components/TableComponent';
import dayjs from 'dayjs';
import { BiPlus, BiShow, BiTrash } from 'react-icons/bi';
import { useHistory } from "react-router";
import { deleteNews, getNews, updateNews } from '../../api/news.service';
import { toast } from 'sonner';
import Modal from '../../components/Modal';
import { deleteTexts, getTexts } from '../../api/texts.service';

const navigation = ["All", "Active", "Draft"]

const columns = [
    { 
        header: 'Last update',
        accessor: 'date'
    },
    { 
        header: 'Status',
        accessor: 'type'
    },
    { 
        header: 'Detail',
        accessor: 'detail'
    },
    { 
        header: 'Words',
        accessor: 'delivered'
    },
    { 
        header: 'Level',
        accessor: 'read'
    },
    { 
        header: 'Actions',
        accessor: 'actions'
    },
]





const Texts = () => {

    const history = useHistory();
    const [activeTab, setActiveTab] = useState(navigation[0])

    const [dataTable, setDataTable] = useState([])

    const [selectedItem, setSelectedItem] = useState('')
    const [isVisibleModalDelete, setisVisibleModalDelete] = useState(false)


    const handleGetNews = async () =>{
        let data = await getTexts()
        console.log("data texts", data)

        if(data?.data?.results){
            setDataTable(data?.data?.results.map((c)=>{
                return {
                    date: {
                        data: 'data',
                        dataComponent: (
                            <div>
                                <div style={{fontWeight: 'bold', color: 'black'}}>
                                    {dayjs(c?.lastUpdate).format('DD MMM YYYY')}
                                </div>
                                <div>
                                    {dayjs(c?.lastUpdate).format('HH:mm[h]')}
                                </div>
                            </div>
                        )
                    },
                    type: {
                        data: c?.active,
                        dataComponent: (
                            <div className={cn(
                                c?.active ? "status-green-dark" : "status-red-dark",
                                styles.status
                            )}>
                                {c?.active ? "Active" : "Draft"}
                            </div>
                        )
                    },
                    detail: {
                        data: 'detail',
                        dataComponent: (
                            <div>
                                <div style={{fontWeight: 'bold', color: 'black'}}>
                                    {c?.title?.find(c => c?.lang === 'en')?.trans}
                                </div>
                                <p style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: 200}}>
                                    {c?.description?.find(c => c?.lang === 'en')?.trans}
                                </p>
                            </div>
                        )
                    },
                    delivered: {
                        data: c?.analysis?.words,
                        dataComponent: c?.analysis?.words
                    },
                    read: {
                        data: c?.level,
                        dataComponent: c?.level
                    },
                    actions: {
                        data: 'actions',
                        dataComponent: (
                            <div style={{ display: "flex", alignItems: "center"}}>
                                <button style={{color: 'blue'}} onClick={()=>history.push(`/texts/content/${c?._id}`)}>
                                    View Content
                                </button>
                                <button style={{color: 'blue', marginLeft: 20}} onClick={()=>history.push(`/texts/edit/${c?._id}`)}>
                                    Edit
                                </button>
                                <button style={{color: 'red', marginLeft: 20}} onClick={()=>{
                                    setSelectedItem(c)
                                    setisVisibleModalDelete(true)
                                }} >
                                    Delete
                                </button>
                            </div>
                        )
                    }
                }
            }))
        }
    }

    useEffect(() => {
        handleGetNews()
    }, [])

    const handleDelete = async () =>{
        let response = await deleteTexts(selectedItem)
        console.log("response", response)

        toast.success('Deleted Successfully')
        handleGetNews()
    }

    return (
        <div>
            <Modal
                visible={isVisibleModalDelete}
                onClose={() => {
                    setSelectedItem(null);
                    setisVisibleModalDelete(false);
                }}
            >
                <div style={{ margin: "48px 6px", width: "100%", fontSize: 16 }}>Are you sure you want to delete the text? ({selectedItem?.title?.find(c => c?.lang === 'en')?.trans})</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                    className={cn("button-stroke button-small", styles.button)}
                    style={{ marginRight: 10 }}
                    onClick={() => {
                        setSelectedItem(null);
                        setisVisibleModalDelete(false);
                    }}
                >
                    <span>Cancel</span>
                </button>
                <button className={cn("button-stroke-red button-small", styles.button)} onClick={handleDelete}>
                    <span>Delete</span>
                </button>
                </div>
            </Modal>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30}} className={cn(styles.nav, "tablet-hide")}>
                {navigation.map((x, index) => (
                    <button
                        className={cn(styles.link, {
                        [styles.active]: x === activeTab,
                        })}
                        onClick={() => setActiveTab(x)}
                        key={index}
                    >
                        {x}
                    </button>
                ))}
            </div>
            <TableComponent 
                title={activeTab}
                columns={columns}
                data={activeTab === 'All' ? dataTable : dataTable?.filter(c => c?.type?.data === activeTab)}
                isSearch={false}
                isFilter
                isButton
                buttonText={"New Text"}
                buttonIcon={<BiPlus />}
                buttonHandler={()=>history.push('/texts/add')}
            />
        </div>
    );
}

export default Texts;
import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./BugsReports.module.sass";
import { toast } from 'sonner'
import TableComponent from "../../components/TableComponent";
import { BiPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import AddBugModal from "./components/AddBugModal";
import { updateBug } from "../../api/bug.service";
import { fetchBug } from "../../app/bugs/bugs.slice";
import dayjs from "dayjs";
import ReplyBugModal from "./components/ReplyBugModal";

const BugsReports = () => {

    const [visibleModalAddBug, setVisibleModalAddBug] = useState(false)
    const [visibleModalReplyBug, setVisibleModalReplyBug] = useState(false)

    const [selectedBug, setSelectedBug] = useState('')

    const dispatch = useDispatch();

    const { bugs, user } = useSelector((state) => state)

    const [dataTable, setDataTable] = useState([])


    const handleDeleteBug = async (bug) =>{

      let json = {
          bugType: bug?.bugType,
          lang: bug?.lang,
          _id: bug?._id,
          active: false
      }

      const resp = await updateBug(json)
      console.log("resp", resp)
      if(resp?.status === 200){
        toast.success('Bug deleted')
        dispatch(fetchBug());
      }else{
        toast.error(`Bug not deleted (${resp?.response?.data?.message})`)
      }
      
    }

    useEffect(() => {
      console.log("bugs", bugs)
      if(bugs?.value?.length > 0){
        let bugsArray = bugs?.value

        // bugsArray.sort((a, b) => a?.bugType - b?.bugType )
        let bugArray_ = [...bugsArray]
        console.log("bugsArray", bugArray_?.reverse())


        setDataTable(bugArray_?.map((bug)=>{
          return{
            card: {
              data: bug?.card?.meaning?.trans,
              dataComponent: <p title={bug?.card?.meaning?.trans} class={styles.w40}>{bug?.card?.meaning?.trans}</p> 
            },
            reason: {
              data: bug?.reason,
              dataComponent: <p title={bug?.reason} class={styles.w40}>{bug?.reason}</p> 
            },
            pinyin: {
              data: bug?.card?.meaning?.pinyin,
              dataComponent:  bug?.card?.meaning?.pinyin
            },
            char: {
              data: bug?.card?.meaning?.character,
              dataComponent: bug?.card?.meaning?.character
            },
            report_by: {
              data: bug?.createdBy?.name,
              dataComponent:  bug?.createdBy?.name
            },
            status: {
              data: bug?.active,
              dataComponent: bug?.active ? (
                <span style={{color: '#EC7D5A'}}>
                  Pending
                </span>
              ) : (
                <span style={{ color: 'green'}}>
                  Solved
                </span>
              )
            },
            lang: {
              data: bug?.lang?.toUpperCase(),
              dataComponent:  bug?.lang?.toUpperCase()
            },
            date: {
              data: bug?.date,
              dataComponent: <span>{dayjs(bug?.date).format('DD MMM YYYY, HH:mm')}</span>
            },
            bugType:{
              data: bug?.bugType,
              dataComponent:  bug?.bugType
            },
            actions:{
              data: "actions",
              dataComponent: <div style={{ display: "flex", alignItems: "center"}}>
                
                  <button style={{color: 'black'}} onClick={()=>{
                    setVisibleModalReplyBug(true)
                    setSelectedBug(bug)
                  }}>
                      Reply
                  </button>
                  {user?.value?.role === 'ADMIN' &&
                    <button style={{color: 'blue', marginLeft: 20}} onClick={()=>window.open(`/card/edit/${bug?.card?._id}`, "_blank")}>
                        Review
                    </button>
                  }
                  <button style={{color: 'red', marginLeft: 20}} onClick={()=>handleDeleteBug(bug)} >
                      Delete
                  </button>
              </div>
            }
          }
        }))
      }
    }, [bugs])
    

    let columns = [
      {
        header: 'Char',
        accessor: 'char'
      },
      {
        header: 'Lang',
        accessor: 'lang'
      },
      {
        header: 'Error type',
        accessor: 'bugType'
      },
      {
        header: 'Reason',
        accessor: 'reason'
      },
      {
        header: 'Report By',
        accessor: 'report_by'
      },
      {
        header: 'Status',
        accessor: 'status'
      },
      {
        header: "Date",
        accessor: 'date'
      },
      {
        header: 'Action',
        accessor: 'actions'
      },
    ]
    

    const buttonHandler = () =>{
      setVisibleModalAddBug(true)
    }

    return (
      <>
        <AddBugModal visible={visibleModalAddBug} setVisible={setVisibleModalAddBug} />
        <ReplyBugModal visible={visibleModalReplyBug} setVisible={setVisibleModalReplyBug} selectedBug={selectedBug} />
        <TableComponent 
            title="Bugs / Reports"
            columns={columns}
            data={dataTable}
            isButton
            isFilter
            filterAccessor="lang"
            searchPlaceholder="Search Bug..."
            buttonHandler={buttonHandler}
            buttonText="Add Bug"
            buttonIcon={<BiPlus size="28" />}
        />
      </>
    );
};

export default BugsReports;

import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, useLocation } from "react-router-dom";
import Icon from "../Icon";
import Search from "./Search";
import Messages from "./Messages";
import Notification from "./Notification";
import User from "./User";
import { useSelector } from "react-redux";

const defaultLocation = { route: "/deck/add", label: "Create new deck", role: ['TEACHER', 'ADMIN'] };

const BUTTON_LOCATIONS = {
  "": defaultLocation,
  classes: { route: "/classes/add", label: "Create new class", role: ['TEACHER', 'ADMIN'] },
  cards: { route: "/card/add", label: "Create new card", role: ['ADMIN'] },
};

const Header = ({ onOpen }) => {
  const { user } = useSelector((state)=> state)
  let location = useLocation();
  const [visible, setVisible] = useState(false);
  const handleClick = () => {
    onOpen();
    setVisible(false);
  };
  const buttonLocation = BUTTON_LOCATIONS[location.pathname.split("/")[1]];

  console.log("buttonLocation", buttonLocation)

  return (
    <header className={styles.header}>
      <button className={styles.burger} onClick={() => handleClick()}></button>
      {/* <Search className={cn(styles.search, { [styles.visible]: visible })} /> */}
      <button className={cn(styles.buttonSearch, { [styles.active]: visible })} onClick={() => setVisible(!visible)}>
        <Icon name="search" size="24" />
      </button>
      <div className={styles.control} onClick={() => setVisible(false)}>
        {buttonLocation?.role?.includes(user?.value?.role) &&
          <Link className={cn("button", styles.button)} to={buttonLocation.route}>
            <Icon name="add" size="24" />
            <span>{buttonLocation.label}</span>
          </Link>
        }
        {/* <Messages className={styles.messages} />
        <Notification className={styles.notification} /> */}
        <User className={styles.user} />
      </div>
      {/* <div className={styles.btns}>
        <Link className={styles.link} to="/sign-in">
          Sign in
        </Link>
        <Link className={cn("button", styles.button)} to="/sign-up">
          Sign up
        </Link>
      </div> */}
    </header>
  );
};

export default Header;

import React from "react";
import styles from "./Decks.module.sass";
import TableComponent from "../../../../../components/TableComponent";
import { BiPlus } from "react-icons/bi";
import useState from "react-usestateref";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Modal from "../../../../../components/Modal";

const columns = [
  {
    header: "Deck name",
    accessor: "deckName",
  },
  {
    header: "Category",
    accessor: "category",
  },
  {
    header: "Level",
    accessor: "level",
  },
  {
    header: "Actions",
    accessor: "actions",
  },
];

const Decks = ({ decksSelected, setDecksSelected, isEdit }) => {
  const { decks } = useSelector((state) => state);

  const [visibleModal, setVisibleModal] = useState(false);
  const [decksTable, setDecksTable] = useState([]);

  const [deckTable, setDeckTable, deckTableRef] = useState([]);

  useEffect(() => {
    if (isEdit && decksSelected && deckTable.length === 0) {
      let _deck = [];

      decksSelected?.forEach((c) => {
        _deck.push(handleParseDataDeck(c));
      });

      if (_deck.length > 0) {
        setDeckTable(_deck);
      }
    }
  }, [isEdit, decksSelected, deckTable]);

  const handleParseDataDeck = (deck) => {
    return {
      deckName: {
        data: deck?.title,
        dataComponent: deck?.title,
      },
      category: {
        data: deck?.category?.title,
        dataComponent: deck?.category?.title,
      },
      level: {
        data: deck?.level,
        dataComponent: deck?.level,
      },
      _id: deck?._id,
      actions: {
        data: "actions",
        dataComponent: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <button style={{ color: "blue" }} onClick={() => window.open(`/deck/edit/${deck?._id}`, "_blank")}>
              View
            </button>
            <button style={{ color: "red", marginLeft: 20 }} onClick={() => handleDeleteDeckTableID(deck?._id)}>
              Delete
            </button>
          </div>
        ),
      },
    };
  };

  const handleDeleteDeckTableID = (id) => {
    setDeckTable((deckTable) => deckTable?.filter((c) => c._id !== id));
    setDecksSelected(deckTable?.filter((c) => c._id !== id)?.map((c) => c?._id));
  };

  const handleAddDeckTableID = (deck) => {
    if (!deckTableRef.current?.find((c) => c._id === deck?._id)) {
      let _deckTable = [...deckTableRef.current, handleParseDataDeck(deck)];
      setDeckTable(_deckTable);
      setDecksSelected(_deckTable?.map((c) => c?._id));
      setVisibleModal(false);
    }
  };

  useEffect(() => {
    if (decks?.value) {
      setDecksTable(
        decks?.value?.map((deck) => {
          return {
            deckName: {
              data: deck?.title,
              dataComponent: deck?.title,
            },
            category: {
              data: deck?.category?.title,
              dataComponent: deck?.category?.title,
            },
            level: {
              data: deck?.level,
              dataComponent: deck?.level,
            },
            _id: deck?._id,
            actions: {
              data: "actions",
              dataComponent: (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button style={{ color: "blue" }} onClick={() => handleAddDeckTableID(deck)}>
                    Add
                  </button>
                </div>
              ),
            },
          };
        })
      );
    }
  }, [decks]);

  const handleShowModalAddDeck = () => {
    setVisibleModal(true);
  };

  return (
    <div>
      <Modal outerClassName={styles.outer} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <div className={styles.success}>
          <TableComponent title="decks" data={decksTable} columns={columns} />
        </div>
      </Modal>
      <TableComponent
        data={deckTable}
        columns={columns}
        isButton={true}
        buttonText="Add Deck"
        buttonIcon={<BiPlus size="24" />}
        buttonHandler={handleShowModalAddDeck}
        title="Decks"
      />
    </div>
  );
};

export default Decks;

import React, { useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../../Icon";
import Modal from "../../../Modal";
import Schedule from "../../../Schedule";
import { useHistory } from "react-router";

const Control = ({ id, className, startDate, setStartDate, startTime, setStartTime, onDelete }) => {
  const history = useHistory();
  const [visibleModal, setVisibleModal] = useState(false);

  const actions = [
    // {
    //   icon: "calendar",
    //   action: () => setVisibleModal(true),
    // },
    {
      icon: "edit",
      action: () => history.push(`/classes/edit/${id}`),
    },
    {
      icon: "trash",
      action: onDelete,
    },
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
          <button className={styles.button} key={index} onClick={x.action}>
            <Icon name={x.icon} size="20" />
          </button>
        ))}
      </div>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <Schedule startDate={startDate} setStartDate={setStartDate} startTime={startTime} setStartTime={setStartTime} />
      </Modal>
    </>
  );
};

export default Control;

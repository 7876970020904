import React, { useEffect, useState } from "react";
import styles from "./Add.module.sass";
import NameAndInfo from "./components/NameAndInfo";
import Image from "./components/Image";
import Decks from "./components/Decks";
import Video from "./components/Video";
import TopWords from "./components/TopWords";
import Price from "./components/Price";
import { useHistory, useParams } from "react-router";
import Icon from "../../../components/Icon";
import classNames from "classnames";
import dayjs from "dayjs";
import Preview from "./components/Preview";
import { useSelector } from "react-redux";
import { createTexts, createTextsMedia, getTextID, updateTexts } from "../../../api/texts.service";
import { toast } from "sonner";
import SuitableForHsk from "./components/SuitableForHsk";

const AddText = ({ isEdit }) => {
  const history = useHistory();
  const { id } = useParams();

  const { category } = useSelector((state) => state);

  const [objectTitle, setObjectTitle] = useState({
    ES: "",
    EN: "",
    FR: "",
    RU: "",
    PT: "",
  });
  const [objectTextarea, setObjectTextarea] = useState({
    ES: "",
    EN: "",
    FR: "",
    RU: "",
    PT: "",
  });
  const [textAvailability, setTextAvailability] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);

  const [categorySelected, setCategorySelected] = useState("");
  const [subCategorySelected, setSubCategorySelected] = useState("");

  const [levelSelected, setLevelSelected] = useState("");

  const [imageSelected, setImageSelected] = useState("");
  const [videoSelected, setVideoSelected] = useState("");

  const [priceAvailability, setPriceAvailability] = useState(false);
  const [textPrice, setTextPrice] = useState("");

  const [decksSelected, setDecksSelected] = useState([]);
  const [suitableForHskSelected, setSuitableForHskSelected] = useState([]);

  const [cardsSelected, setCardsSelected] = useState([]);

  const [text, setText] = useState("");

  const [loading, setLoading] = useState(false);

  const handleGetTextID = async () => {
    let response = await getTextID("", id);

    console.log("res id", response);

    if (response?.status === 200) {
      console.log("response?.data --> ", response?.data);

      setCategorySelected(response?.data?.category?._id);
      setSubCategorySelected(response?.data?.subCategory?._id);
      setLevelSelected(response?.data?.level);
      setObjectTitle({
        ES: response?.data?.title?.find((c) => c?.lang === "es")?.trans,
        EN: response?.data?.title?.find((c) => c?.lang === "en")?.trans,
        FR: response?.data?.title?.find((c) => c?.lang === "fr")?.trans,
        RU: response?.data?.title?.find((c) => c?.lang === "ru")?.trans,
        PT: response?.data?.title?.find((c) => c?.lang === "pt")?.trans,
      });
      setObjectTextarea({
        ES: response?.data?.description?.find((c) => c?.lang === "es")?.trans,
        EN: response?.data?.description?.find((c) => c?.lang === "en")?.trans,
        FR: response?.data?.description?.find((c) => c?.lang === "fr")?.trans,
        RU: response?.data?.description?.find((c) => c?.lang === "ru")?.trans,
        PT: response?.data?.description?.find((c) => c?.lang === "pt")?.trans,
      });
      setCardsSelected(response?.data?.topWords);
      setDecksSelected(response?.data?.decks);
      if (response?.data?.suitableForHsk) {
        setSuitableForHskSelected([response?.data?.suitableForHsk]);
      }
      setImageSelected(response?.data?.imageUrl);
      setVideoSelected(response?.data?.videoUrl);
      setText(response?.data?.text);
      setPriceAvailability(response?.data?.premium?.active);
      setTextPrice(response?.data?.premium?.cost);
      setTextAvailability(response?.data?.active);
    }
  };

  useEffect(() => {
    if (id) {
      handleGetTextID();
    }
  }, [id]);

  useEffect(() => {
    if (categorySelected && category) {
      setSubCategoryOptions(
        category?.value
          ?.filter((val) => val._id === categorySelected)?.[0]
          ?.subCategories?.map((val) => {
            return {
              label: val.title,
              value: val._id,
            };
          })
      );
    }
  }, [categorySelected, category]);

  useEffect(() => {
    if (category?.value?.length > 0) {
      setCategoryOptions(
        category?.value?.map((val) => {
          return {
            label: val.title,
            value: val._id,
          };
        })
      );
    }
  }, [category]);

  const nameAndInfoProps = {
    objectTitle,
    setObjectTitle,
    objectTextarea,
    setObjectTextarea,
    textAvailability,
    setTextAvailability,
    categoryOptions,
    setCategoryOptions,
    subCategoryOptions,
    setSubCategoryOptions,
    categorySelected,
    setCategorySelected,
    subCategorySelected,
    setSubCategorySelected,
    levelSelected,
    setLevelSelected,
  };

  const imageProps = {
    imageSelected,
    setImageSelected,
    isEdit,
  };

  const videoProps = {
    videoSelected,
    setVideoSelected,
  };

  const decksProps = {
    decksSelected,
    setDecksSelected,
    isEdit,
  };

  const suitableForHskProps = {
    suitableForHskSelected,
    setSuitableForHskSelected,
    isEdit,
  };

  console.log("suitableForHskSelected --> ", suitableForHskSelected);

  const topWordsProps = {
    cardsSelected,
    setCardsSelected,
    isEdit,
  };

  const priceProps = {
    priceAvailability,
    setPriceAvailability,
    textPrice,
    setTextPrice,
  };

  const textProps = {
    text,
    setText,
  };

  const handleSaveChanges = async () => {
    setLoading(true);

    console.log("suitableForHskSelected --> ", suitableForHskSelected);

    let json = {
      active: textAvailability,
      title: Object.entries(objectTitle).map((c) => {
        return {
          active: c?.[1] ? true : false,
          lang: c?.[0]?.toLowerCase(),
          trans: c?.[1],
        };
      }),
      description: Object.entries(objectTextarea).map((c) => {
        return {
          active: c?.[1] ? true : false,
          lang: c?.[0]?.toLowerCase(),
          trans: c?.[1],
        };
      }),
      category: subCategorySelected,
      level: levelSelected,
      topWords: cardsSelected,
      decks: decksSelected,
      suitableForHsk: suitableForHskSelected[0],
      text,
      premium: {
        active: priceAvailability,
        cost: textPrice || 0,
      },
    };

    console.log("json --> ", json);

    let textId = null;
    if (isEdit) {
      json._id = id;
      textId = id;
      let response = await updateTexts(json);
      setLoading(false);
      if (response?.data?._id) {
        toast.success("Text updated succesfully");
      }
    } else {
      console.log("json", json);
      let response = await createTexts(json);
      setLoading(false);
      console.log("response", response);
      if (response?.data?._id) {
        textId = response?.data?._id;
        toast.success("Text created succesfully");

        history.push(`/texts/content/${response?.data?._id}`);
      } else {
        toast.error("An error occurred");
      }
    }

    if (imageSelected && typeof imageSelected !== "string") {
      let bodyImage = new FormData();
      bodyImage.append("file", imageSelected);
      bodyImage.append("type", "image");
      bodyImage.append("_id", textId);
      await createTextsMedia(bodyImage);
    }

    if (videoSelected && typeof videoSelected !== "string") {
      let bodyVideo = new FormData();
      bodyVideo.append("file", videoSelected);
      bodyVideo.append("type", "video");
      bodyVideo.append("_id", textId);
      await createTextsMedia(bodyVideo);
    }
  };

  return (
    <div className={styles.spacey}>
      <div style={{ padding: "0px 40px 40px 40px" }} className={styles.row}>
        <div className={styles.col}>
          <NameAndInfo {...nameAndInfoProps} />
          <Image {...imageProps} />
          <Video {...videoProps} />
          <TopWords {...topWordsProps} />
          <Decks {...decksProps} />
          <SuitableForHsk {...suitableForHskProps} />
          <Price {...priceProps} />
        </div>
        <div className={styles.col}>
          <div style={{ width: "100%", position: "sticky", top: 110 }}>
            <Preview {...textProps} isEdit={isEdit} />
          </div>
        </div>
      </div>
      <div
        style={{
          background: "black",
          width: "100%",
          position: "sticky",
          left: 0,
          bottom: 0,
          zIndex: 999,
        }}
      >
        <div
          style={{
            background: "white",
            padding: 14,
            position: "sticky",
            bottom: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className={styles.info}>
            <Icon name="check-all" size="24" />
            {"Last edit "}
            <span>{dayjs(new Date()).format("MMM DD, YYYY - HH:mm[h]")}</span>
            <u style={{ marginLeft: 8 }}>@Alfonso</u>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              disabled={loading || !text}
              onClick={handleSaveChanges}
              style={{ marginLeft: 20 }}
              className={classNames("button", styles.button)}
            >
              {loading ? "Loading..." : <span>Save changes</span>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddText;

import React, { useRef, useState } from "react";
import styles from "./Table.module.sass";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import { numberWithCommas } from "../../utils";
import Card from "../Card";
import Form from "../Form";
import { BiPlus } from "react-icons/bi";
import { useEffect } from "react";
import { removeDiacritics } from "../../utils/removeDiacritics";
import useOnScreen from "../../hooks/useOnScreen";
import Dropdown from "../Dropdown";


const TableComponent = ({ 
    className,
    title,
    columns,
    data,
    isSearch = true,
    isFilter = false,
    filterAccessor,
    handleSearchAPI,
    searchPlaceholder,
    isButton = false,
    buttonHandler,
    buttonText,
    buttonIcon,
    isCardStyle = true,
    onPageLoad,
    contentRight,
    paginate,
    maxHeight = '62vh'
}) => {

    const [dataTable, setDataTable] = useState([])
    const [search, setSearch] = useState('')

    const [filter, setFilter] = useState('Filter')
    const [optionsFilter, setOptionsFilter] = useState([])

    const [pages, setPages] = useState(0)

    useEffect(() => {
      if(paginate){
        setPages(paginate)
      }
    }, [paginate])

    const ref = useRef(null)
    const isVisible = useOnScreen(ref)

    useEffect(() => {
      if(isVisible && !search && onPageLoad){
        onPageLoad()
      }
    }, [isVisible])

    useEffect(() => {
      if(isVisible && paginate){
        console.log("Pasando página")
        setPages(pages => pages + paginate)
      }
    }, [isVisible, paginate])
    

    const handleSearch = (e) =>{
      setSearch(e)

      if(handleSearchAPI){
        return handleSearchAPI(e.trim())
      }

      if(e.trim()?.length === 0){
        setDataTable(data)
      }else{
        let filteredData = []

        data?.forEach(d =>{
          columns.forEach(column =>{
            if(removeDiacritics(String(d?.[column.accessor]?.data)?.toLowerCase())?.includes(removeDiacritics(e?.toLowerCase()))){
              filteredData.push(d)
            }
          })
        })

        setDataTable(filteredData)
      }
    }


    const handleSetFilter = (e) =>{
      setFilter(e)
      if(e === 'Filter'){
        setDataTable(data)
      }else{

        let filteredData = []

        data?.forEach(d =>{
          if(removeDiacritics(d?.[filterAccessor]?.data?.toLowerCase())?.includes(removeDiacritics(e?.toLowerCase()))){
            filteredData.push(d)
          }
        })

        setDataTable(filteredData)
      }
    }

    useEffect(() => {
      if(isFilter && filterAccessor){
        let optionsFilter = new Set()
        optionsFilter.add('Filter')
        data.forEach((d)=>{
          if(d?.[filterAccessor]?.data){
            optionsFilter.add(d?.[filterAccessor]?.data)
          }
        })
        let array_ = Array.from(optionsFilter)
        setOptionsFilter(filter => array_?.map((c)=>{
          return{
            value: c,
            label: c
          }
        }))
        console.log("Array.from(optionsFilter)", Array.from(optionsFilter))
      }
      setDataTable(data)
    }, [data])
    

  return (
    <Card 
        isStyle={isCardStyle}
        maxHeight
        className={cn(styles.card, className)} 
        title={title} 
        helper={data?.length}
        head={
          <div className={styles.flex}>
            {isSearch &&
              <Form
                  className={styles.form}
                  value={search}
                  setValue={handleSearch}
                  placeholder={searchPlaceholder || "Search..."}
                  type="text"
                  name="search"
                  icon="search"
              />
            }
            {isFilter &&
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={filter}
                setValue={handleSetFilter}
                options={optionsFilter}
                small
              />
            }
            {isButton &&
              <button onClick={buttonHandler} className={cn("button", styles.button)}>
                  {buttonIcon}
                  <span>{buttonText}</span>
              </button>
            }
            {contentRight &&
              <>
                {contentRight}
              </>
            }
          </div>
        } 
        classTitle="title-purple"
    >
        <div 
          className={styles.wrapper} 
          style={{
            maxHeight
          }}
        >
          <div className={styles.table}>
              <div className={styles.row} style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                zIndex: 99
              }}>
                {columns.map((column, index) =>(
                  <div key={index} className={styles.col}>{column.header}</div>
                ))}
              </div>
                {dataTable.map((x, index) => {
                  if(paginate){
                    if(index < pages){
                      return(
                        <div tabIndex={index} className={cn(styles.row, styles.rowPersonalized)} key={index}>
                            {columns.map((column, index_column) =>(
                              <div key={index_column} className={styles.col}>{x?.[column?.accessor]?.dataComponent}</div>
                            ))}
                        </div>
                      )
                    }
                  }else{
                    return(
                      <div tabIndex={index} className={cn(styles.row, styles.rowPersonalized)} key={index}>
                          {columns.map((column, index_column) =>(
                            <div key={index_column} className={styles.col}>{x?.[column?.accessor]?.dataComponent}</div>
                          ))}
                      </div>
                    )
                  }
                })}
                <div ref={ref} />
          </div>
        </div>
    </Card>
  );
};

export default TableComponent;

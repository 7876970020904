import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";

const Page = ({ wide, children, isHeader = true,  isPadding = true, title }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={styles.page}>
        <Sidebar
          className={cn(styles.sidebar, { [styles.visible]: visible })}
          onClose={() => setVisible(false)}
        />
        {isHeader &&
          <Header onOpen={() => setVisible(true)} />
        }
        <div className={cn(styles.inner, !isPadding && styles.noPadding)}>
          <div
            className={cn(styles.container, {
              [styles.wide]: wide,
            })}
            
          >
            {title && <div style={{padding: isPadding ? '' : '40px 40px 0px 40px' }} className={cn("h3", styles.title)}>{title}</div>}
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Page);

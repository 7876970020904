import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import { logOut } from "../../../app/user/user.slice";
import { useDispatch, useSelector } from "react-redux";

const User = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state)
  

  const items = [
    // {
    //   menu: [
    //     {
    //       title: "Profile",
    //       url: "/shop",
    //     },
    //     {
    //       title: "Edit profile",
    //       url: "/settings",
    //     },
    //   ],
    // },
    // {
    //   menu: [
    //     {
    //       title: "Analytics",
    //       icon: "bar-chart",
    //       url: "/customers/overview",
    //     },
    //     {
    //       title: "Affiliate center",
    //       icon: "ticket",
    //       url: "/affiliate-center",
    //     },
    //     {
    //       title: "Explore creators",
    //       icon: "grid",
    //       url: "/explore-creators",
    //     },
    //   ],
    // },
    // {
    //   menu: [
    //     {
    //       title: "Upgrade to Pro",
    //       icon: "leaderboard",
    //       color: true,
    //       url: "/upgrade-to-pro",
    //     },
    //   ],
    // },
    {
      menu: [
        // {
        //   title: "Account settings",
        //   url: "/settings",
        // },
        {
          title: "Log out",
          onClick: () => dispatch(logOut()),
        },
      ],
    },
  ];

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.user, className, { [styles.active]: visible })}>
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          {user?.value?.avatarUrl ? (
            <img src={user?.value?.avatarUrl} alt="Avatar" />
          ) : (
            <span>
              {user?.value?.name?.split('')?.[0]?.toUpperCase()}
              {user?.value?.name?.split('')?.[1]?.toUpperCase()}
            </span>
          )}
        </button>
        <div className={styles.body}>
          <div style={{ fontSize: 18, marginLeft: 10}}>
            {user?.value?.email}
          </div>
          {items.map((item, index) => (
            <div className={styles.menu} key={index}>
              {item.menu.map((x, index) =>
                x.url ? (
                  <NavLink
                    className={cn(styles.item, { [styles.color]: x.color })}
                    activeClassName={styles.active}
                    to={x.url}
                    onClick={() => {
                      if (item.onClick) item.onClick(item);
                      setVisible(false);
                    }}
                    key={index}
                  >
                    {x.icon && <Icon name={x.icon} size="24" />}
                    {x.title}
                  </NavLink>
                ) : (
                  <button
                    className={styles.item}
                    onClick={() => {
                      if (x.onClick) x.onClick(x);
                      setVisible(false);
                    }}
                    key={index}
                  >
                    {x.title}
                  </button>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;

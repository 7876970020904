import React from 'react'
import { useEffect } from 'react';
import cn from "classnames";
import styles from "./NewCard.module.sass";
import { useHistory, useParams, useLocation } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCreateCard, fetchEditCard, fetchUpdateCard } from '../../app/cards/cards.slice';
import CardPreview from '../../components/CardPreview';
import CardInfo from './CardInfo';
import Context from './Context';
import Decks from './Decks';
import { useState } from 'react';
import { parseSearchClassesTags } from '../../utils';
import { fetchGrammar } from '../../app/grammar/grammar.slice';
import Loader from '../../components/Loader';
import classNames from 'classnames';
import Modal from '../../components/Modal';
import TableComponent from '../../components/TableComponent';
import Icon from '../../components/Icon';
import dayjs from 'dayjs';
import { BiLeftArrowAlt, BiPlus, BiRightArrowAlt } from 'react-icons/bi';
import { isEqual } from 'lodash';
import { uploadVideosCard } from '../../api/cards.service';
import Report from './Report/Report';
import { updateBug } from '../../api/bug.service';
import { toast } from 'sonner';
import { CgArrowLeftR, CgArrowRightR } from 'react-icons/cg';
import { MdKeyboardControlKey } from 'react-icons/md';

const columns = [
    {
      header: 'Deck name',
      accessor: 'deckName'
    },
    {
      header: 'Category',
      accessor: 'category'
    },
    {
      header: 'Level',
      accessor: 'level'
    },
    {
      header: 'Actions',
      accessor: 'actions'
    },
]

const NewCard = ({ isNew }) => {

    const location = useLocation()
    const history = useHistory();

    const params = new URLSearchParams(location.search);

    const { user, cards, grammar, classification, decks } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [visibleModal, setVisibleModal] = useState(false)

    const [cardObject, setCardObject] = useState('')
    const { cardId } = useParams();

    const [grammarOptions, setGrammarOptions] = useState([])
    const [classificationOptions, setClassificationOptions] = useState([])

    const [cardAvailavility, setCardAvailavility] = useState(true)
    const [cardIdBlock, setCardIdBlock] = useState('')
    const [grammars, setGrammars] = useState([])
    const [classification_, setClassification] = useState([])

    const [lastUpdate, setLastUpdate] = useState(new Date())

    const [context1, setContext1] = useState('')
    const [context2, setContext2] = useState('')
    const [meaningWord, setMeaningWord] = useState('')

    const [nextCard, setNextCard] = useState('')
    const [prevCard, setPrevCard] = useState('')

    const [deckTable, setDeckTable] = useState([])
    const [decksTable, setDecksTable] = useState([])

    const [updatedBy, setUpdatedBy] = useState('')

    const [newCardEdit, setNewCardEdit] = useState('')

    const [deckId, setDeckId] = useState('')

    const [initialObject, setInitialObject] = useState('')
    const [finalObject, setFinalObject] = useState('')
    const [objectEqual, setObjectEqual] = useState(false)

    const [cardDeck, setCardDeck] = useState('')

    const [bugs, setBugs] = useState([])

    const navigation = ["Word", "Context 1", "Context 2"]

    const [activeTab, setActiveTab] = useState(navigation[0])

    const cardInfoProps = {
        cardAvailavility,
        setCardAvailavility,
        cardId: cardIdBlock,
        setCardId: setCardIdBlock,
        grammars,
        setGrammars,
        classification: classification_,
        setClassification,
        grammarOptions,
        classificationOptions
    }

    const cardContextProps = {
        context1,
        setContext1,
        context2,
        setContext2,
        meaningWord,
        navigation,
        activeTab,
        setActiveTab,
        setMeaningWord
    }

    const handleDeleteDeckTableID = (id) =>{
        setDeckTable(deckTable => deckTable?.filter(c => c._id !== id ))
    }

    const handleAddDeckTableID = (deck) =>{
        if(!deckTable?.find(c => c._id === deck?._id )){
            setDeckTable(deckTable => [...deckTable, handleParseDataDeck(deck)])
            setVisibleModal(false)
        }
    }

    const keyDownTextField = async (e) => {
        var keyCode = e.keyCode;

        console.log(e)
        console.log("e.keycode", e.keyCode)

        const isActiveTab = (element) => element === activeTab;


        if(keyCode === 37 && e?.ctrlKey === true && prevCard){ // flecha derecha
            await handleSave(true)
            window.open(`/card/edit/${prevCard}?deckId=${deckId}`, '_self')
            return;
        }

        if(keyCode === 39 && e?.ctrlKey === true && nextCard){ // flecha derecha
            console.log("yendo derecha")
            await handleSave(true)
            window.open(`/card/edit/${nextCard}?deckId=${deckId}`, '_self')
            return;
        }

        if(keyCode === 37 && e?.metaKey === true && prevCard){ // flecha derecha
            await handleSave(true)
            window.open(`/card/edit/${prevCard}?deckId=${deckId}`, '_self')
            return;
        }

        if(keyCode === 39 && e?.metaKey === true && nextCard){ // flecha derecha
            console.log("yendo derecha")
            await handleSave(true)
            window.open(`/card/edit/${nextCard}?deckId=${deckId}`, '_self')
            return;
        }
        

        if(keyCode === 39){ // flecha derecha
            if(navigation.findIndex(isActiveTab) !== 2){
                setActiveTab(navigation?.[navigation.findIndex(isActiveTab)+1])
            }
            return;
        }

        if(keyCode === 37){ // flecha izquierda
            if(navigation.findIndex(isActiveTab) !== 0){
                setActiveTab(navigation?.[navigation.findIndex(isActiveTab)-1])
            }
            return;
        }
    }

    useEffect(() => {
    
        window.addEventListener("keydown", keyDownTextField);
    
        return () => {
            window.removeEventListener("keydown", keyDownTextField);
        }
    }, [activeTab, nextCard, deckId, prevCard, finalObject])
    

    useEffect(async () => {
        let deckId = params.get('deckId')
        setDeckId(deckId)
        if (cardId) {
            let newCardEdit_ = await dispatch(fetchEditCard({ _id: cardId, deck: deckId || '' }));
            console.log("newCardEdit_",newCardEdit_)
            setNewCardEdit(newCardEdit_?.payload)
        }
    }, [cardId]);

    useEffect(() => {
        console.log("grammar --->", grammar?.value)
        setGrammarOptions(grammar?.value?.map((c)=>{
            return{
                label: c?.title,
                value: c?._id
            }
        }))
        setClassificationOptions(classification?.value?.map((c)=>{
            return{
                label: c?.trans || c?.description,
                value: c?._id
            }
        }))
        console.log("classification --->", classification?.value)
    }, [grammar, classification])


    const handleParseDataDeck = (deck) =>{
        return {
            deckName: {
                data: deck?.title,
                dataComponent: deck?.title
            },
            category: {
                data: deck?.category?.title,
                dataComponent: deck?.category?.title
            },
            level: {
                data: deck?.level,
                dataComponent: deck?.level
            },
            _id: deck?._id,
            actions: {
                data: 'actions',
                dataComponent: (
                    <div style={{ display: "flex", alignItems: "center"}}>
                        <button style={{color: 'blue'}} onClick={()=>window.open(`/deck/edit/${deck?._id}`, "_blank")}>
                            View
                        </button>
                        <button style={{color: 'red', marginLeft: 20}} onClick={()=>handleDeleteDeckTableID(deck?._id)} >
                            Delete
                        </button>
                    </div>
                )
            }
        }
    }
    

    useEffect(() => {
        if(newCardEdit && !isNew){
            const editCard = newCardEdit
            console.log("editCard", editCard)
            setCardObject(editCard)
            setCardAvailavility(editCard?.active)
            setCardIdBlock(editCard?.id)
            setGrammars(editCard?.grammars?.map((c)=> {
                return{
                    label: c?.trans || c?.description,
                    value: c?._id
                }
            }))
            setClassification(editCard?.classifications?.map((c)=> {
                return{label: c?.trans, value: c?._id}
            }))
            setContext1(editCard?.examples?.[0])
            setContext2(editCard?.examples?.[1])
            setMeaningWord(editCard?.meaning)
            setLastUpdate(editCard?.lastUpdate)

            setDeckTable(editCard?.decks?.map((c)=>{
                return handleParseDataDeck(c)
            }))

            setCardDeck(editCard?.cardDeck)

            setUpdatedBy(editCard?.updatedBy?.name)

            setNextCard(editCard?.cardDeck?.nextCard)
            setPrevCard(editCard?.cardDeck?.beforeCard)

            setBugs(editCard?.bugs?.filter(bug=> bug.active === true))

            let json = {
                meaning: editCard?.meaning,
                examples: [editCard?.examples?.[0], editCard?.examples?.[1]],
                _id: editCard?._id,
                grammars: editCard?.grammars?.map((c)=> c?._id),
                classifications: editCard?.classifications?.map((c)=> c?._id),
                active: editCard?.active,
                decks: editCard?.decks?.map((c)=> c?._id)
            }

            if(!editCard?.meaning?.order){
                json = {
                    ...json,
                    meaning: {
                        ...json.meaning,
                        order: 0
                    }
                }
            }
            setInitialObject(json)
        }
    }, [newCardEdit])

    useEffect(() => {
        let json = {
            meaning: meaningWord,
            examples: [context1, context2],
            grammars: grammars?.map((c)=> c?.value),
            classifications: classification_?.map((c)=> c?.value),
            active: cardAvailavility,
            decks: deckTable?.map((c)=> c?._id)
        }

        console.log("json", json)

        if(!json?.meaning?.order){
            json = {
                ...json,
                meaning: {
                    ...json.meaning,
                    order: 0
                }
            }
        }

        if(newCardEdit && !isNew){ // cards.editCardValue && !isNew
            console.log("Se pone el ID")
            const editCard = newCardEdit // cards.editCardValue
            json._id = editCard?._id
        }


        setFinalObject(json)

        console.log("json equal", json)
        console.log("initialObject equal", initialObject)

        if (isEqual(json, initialObject)) {
            setObjectEqual(true)
        }else{
            setObjectEqual(false)
        }
    }, [meaningWord, context2, context1, cards, initialObject, isNew, grammars, classification_, cardAvailavility, deckTable ])
    


    useEffect(() => {
        console.log("decks ->>", decks)
        if(decks?.value){
            setDecksTable(decks?.value?.map((deck)=>{
                return{
                    deckName: {
                        data: deck?.title,
                        dataComponent: deck?.title
                    },
                    category: {
                        data: deck?.category?.title,
                        dataComponent: deck?.category?.title
                    },
                    level: {
                        data: deck?.level,
                        dataComponent: deck?.level
                    },
                    _id: deck?._id,
                    actions: {
                        data: 'actions',
                        dataComponent: (
                            <div style={{ display: "flex", alignItems: "center"}}>
                                <button style={{color: 'blue'}} onClick={()=>handleAddDeckTableID(deck)}>
                                    Add
                                </button>
                            </div>
                        )
                    }
                }
            }))
        }
    }, [decks])
    

    const handleShowModalAddDeck = () =>{
        setVisibleModal(true)
    }
    
    const handleUploadVideo = async (obj) =>{
        if(obj?.meaning?.fileExtra){
            let body = new FormData();
            body.append('file', obj?.meaning?.fileExtra)
            body.append('card', cardId)
            body.append('order', 0)
            await uploadVideosCard(body)
        }
        if(obj?.examples?.[0]?.fileExtra){
            let body = new FormData();
            body.append('file', obj?.examples?.[0]?.fileExtra)
            body.append('card', cardId)
            body.append('order', 1)
            await uploadVideosCard(body)
        }
        if(obj?.examples?.[1]?.fileExtra){
            let body = new FormData();
            body.append('file', obj?.examples?.[1]?.fileExtra)
            body.append('card', cardId)
            body.append('order', 2)
            await uploadVideosCard(body)
        }

        return;
    }

    const handleSave = async (noPush) =>{
        if(finalObject?._id){
            let saved = await dispatch(fetchUpdateCard(finalObject))
            console.log("saved", saved)
            toast.success('Card saved successfully')
            await handleUploadVideo(finalObject)
        }else{
            let created = await dispatch(fetchCreateCard(finalObject))
            console.log("created", created)
            if(created?.payload?._id){
                toast.success('Card created successfully')
                await handleUploadVideo({...finalObject, _id: created?.payload?._id})
                // history.push()
                window.open(`/card/edit/${created?.payload?._id}`, '_self')
                return;
            }
        }
        if(!noPush && !deckId) history.push('/cards')
    }

    const handleSolveReport = async (bug) =>{
        let json = {
            bugType: bug.bugType,
            lang: bug?.lang,
            _id: bug._id,
            active: false
        }
        const resp = await updateBug(json)
        if(resp?.data?.card){
            console.log("resp", resp)
            setBugs(bugs => bugs?.filter(c => c?._id !== bug?._id))
            toast.success(`Marked as solved`)
        }else{
            toast.error(`Error (${resp?.response?.data?.message})`)
        }
    }

    return (
        <div className={styles.spacey}>
            {bugs?.map((bug, index)=>(
                <React.Fragment key={index}>
                    <Report 
                        createdBy={bug?.createdBy?.name}
                        date={bug?.lastUpdate}
                        bugType={bug?.bugType}
                        device={bug?.system}
                        reason={bug?.reason}
                        id={bug?._id}
                        handleSolve={handleSolveReport}
                        bug={bug}
                    />
                </React.Fragment>
            ))}
            <div style={{marginTop: bugs?.length > 0 ? 20 : 0, padding: '0px 40px 40px 40px'}} className={styles.row}>
                <div className={styles.col}>
                    <CardInfo {...cardInfoProps} />
                    <Context {...cardContextProps} />
                    <Decks data={deckTable} handleShowModalAddDeck={handleShowModalAddDeck} columns={columns} />
                    <Modal
                        outerClassName={styles.outer}
                        visible={visibleModal}
                        onClose={() => setVisibleModal(false)}
                    >
                        <div className={styles.success}>
                            <TableComponent 
                                title="decks"
                                data={decksTable}
                                columns={columns}
                            />
                        </div>
                    </Modal>
                    
                </div>
                <div className={styles.col}>
                    <div style={{ width: '100%', position: 'sticky', top: 110}}>
                        <CardPreview 
                            card={finalObject}
                            grammar={grammars?.[0]?.label}
                            classificator={classification_?.[0]?.label}
                        />
                    </div>
                </div>
            </div>
            <div
                style={{
                    background: "black",
                    width: '100%',
                    position: "sticky",
                    left: 0,
                    bottom: 0,
                }}
            >
                <div style={{
                    background: 'white',
                    padding: 14,
                    position: 'sticky',
                    bottom: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <div className={styles.info}>
                        <Icon name="check-all" size="24" />
                        {'Last edit '}
                        <span>{dayjs(lastUpdate).format('MMM DD, YYYY - HH:mm[h]')}</span> 
                        {updatedBy &&
                            <u style={{marginLeft: 8}}>@{updatedBy}</u>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                        {deckId &&
                            <small style={{ display: 'flex', marginRight: 20, alignItems: 'center', color: 'lightgray'}}>
                                <span>
                                    Use the <code>ctrl</code> + <code>arrows</code> key to switch tabs
                                </span>
                                <MdKeyboardControlKey size="20" style={{ marginLeft: 10 }} />
                                <CgArrowLeftR size="20" style={{ marginLeft: 5 }}/>
                                <CgArrowRightR size="20" style={{ marginLeft: 5 }} />
                            </small>
                        }
                        {deckId &&
                            <div style={{marginRight: 20}}>
                                <div>
                                    <small style={{color: 'gray'}}>
                                        Deck: {cardDeck?.deck?.title}
                                    </small>
                                </div>
                                <div>
                                    <small style={{color: 'gray'}}>
                                        Position {cardDeck?.actualCard} of {cardDeck?.totalCards}
                                    </small>
                                </div>
                            </div>
                        }
                        {prevCard &&
                            <button onClick={async ()=>{
                                await handleSave(true)
                                window.open(`/card/edit/${prevCard}?deckId=${deckId}`, '_self')
                            }} className={cn("button-stroke-black", styles.button)}>
                                <BiLeftArrowAlt size="28" />
                            </button>
                        }
                        {nextCard &&
                            <button onClick={async ()=>{
                                await handleSave(true)
                                window.open(`/card/edit/${nextCard}?deckId=${deckId}`, '_self')
                            }} style={{marginLeft: 20}} className={cn("button-stroke-black", styles.button)}>
                                <BiRightArrowAlt size="28" />
                            </button>
                        }
                        <button disabled={objectEqual} style={{marginLeft: 20}} className={classNames("button", styles.button)} onClick={()=>handleSave(false)} >
                            {cards.isLoadingCreate ? <Loader className={styles.loader} /> : <span>Save changes</span>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewCard;
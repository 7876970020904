import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { createDeck, deleteDeck, getDeck, getDecks, updateDeck, updateDeckAvatar } from "../../api/decks.service";
import { copyOf } from "../../utils";

export const fetchDecks = createAsyncThunk("decks/fetchDecks", async (data, { rejectWithValue }) => {
  const decks = await getDecks(data);
  console.log("****+decks -> ", decks);
  return decks.data;
});

const fetchDecksReducer = {
  [fetchDecks.pending]: (state, action) => {
    state.isLoading = true;
    state.error = null;
  },
  [fetchDecks.fulfilled]: (state, action) => {
    const decks = action.payload.results;
    if (decks && Array.isArray(decks)) {
      state.value = decks;
      // state.value.sort((a, b) => {
      //   return !a.stat.lastPlayed
      //     ? 1
      //     : !b.stat.lastPlayed
      //     ? -1
      //     : a.stat.lastPlayed < b.stat.lastPlayed
      //     ? 1
      //     : a.stat.lastPlayed > b.stat.lastPlayed
      //     ? -1
      //     : 0;
      // });
    }

    state.error = null;
    state.isLoading = false;
  },
  [fetchDecks.rejected]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const fetchEditDeck = createAsyncThunk("decks/fetchEditDeck", async (data, { rejectWithValue }) => {
  const decks = await getDeck(data);
  console.log("****+getdeck -> ", decks);
  return decks.data;
});

const fetchEditDeckReducer = {
  [fetchEditDeck.pending]: (state, action) => {
    state.isLoading = true;
    state.error = null;
    state.editDeckValue = null;
  },
  [fetchEditDeck.fulfilled]: (state, action) => {
    const deck = action.payload;
    state.editDeckValue = deck;
    state.error = null;
    state.isLoading = false;
  },
  [fetchEditDeck.rejected]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const fetchCreateDeck = createAsyncThunk("decks/fetchCreateDeck", async (data, { rejectWithValue }) => {
  const decks = await createDeck(data);
  console.log("****+deck created -> ", decks);
  return decks.data;
});

const fetchCreateDeckReducer = {
  [fetchCreateDeck.pending]: (state, action) => {
    state.isLoadingCreate = true;
    state.isLoading = true;
    state.error = null;
  },
  [fetchCreateDeck.fulfilled]: (state, action) => {
    const respData = action.payload;
    state.value.push(respData);
    state.error = null;
    state.editDeckValue = null;
    state.isLoadingCreate = false;
    state.isLoading = false;
  },
  [fetchCreateDeck.rejected]: (state, action) => {
    state.isLoadingCreate = false;
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const fetchUpdateDeck = createAsyncThunk("decks/fetchUpdateDeck", async (data, { rejectWithValue }) => {
  const deck = await updateDeck(data);
  console.log("****+decks -> ", deck);
  return deck.data;
});

const fetchUpdateDeckReducer = {
  [fetchUpdateDeck.pending]: (state, action) => {
    state.isLoading = true;
    state.isLoadingCreate = true;
    state.error = null;
  },
  [fetchUpdateDeck.fulfilled]: (state, action) => {
    const respData = action.payload;
    const currentValue = copyOf(current(state.value)).filter((v) => v._id !== respData._id);
    state.value = currentValue;
    state.value.unshift(respData);
    state.error = null;
    state.isLoading = false;
    state.isLoadingCreate = false;
    state.editDeckValue = null;
  },
  [fetchUpdateDeck.rejected]: (state, action) => {
    state.isLoading = false;
    state.isLoadingCreate = false;
    state.error = action.payload;
  },
};

export const fetchUpdateDeckAvatar = createAsyncThunk("decks/fetchUpdateDeckAvatar", async (data, { rejectWithValue }) => {
  const deck = await updateDeckAvatar(data);
  console.log("****+decks -> ", deck);
  return deck.data;
});

const fetchUpdateDeckAvatarReducer = {
  [fetchUpdateDeckAvatar.pending]: (state, action) => {
    state.isLoading = true;
    state.isLoadingCreate = true;
    state.error = null;
  },
  [fetchUpdateDeckAvatar.fulfilled]: (state, action) => {
    const respData = action.payload;
    const currentValue = copyOf(current(state.value)).filter((v) => v._id !== respData._id);
    state.value = currentValue;
    state.value.unshift(respData);
    state.error = null;
    state.isLoading = false;
    state.isLoadingCreate = false;
    state.editDeckValue = null;
  },
  [fetchUpdateDeckAvatar.rejected]: (state, action) => {
    state.isLoading = false;
    state.isLoadingCreate = false;
    state.error = action.payload;
  },
};

export const fetchDeleteDeck = createAsyncThunk("decks/fetchDeleteDeck", async (data, { rejectWithValue }) => {
  const deck = await deleteDeck(data);
  console.log("****+decks -> ", deck);
  return data;
});

const fetchDeleteDeckReducer = {
  [fetchDeleteDeck.pending]: (state, action) => {
    state.isLoading = true;
    state.isLoadingCreate = true;
    state.error = null;
  },
  [fetchDeleteDeck.fulfilled]: (state, action) => {
    const respData = action.payload;
    const currentValue = copyOf(current(state.value)).filter((v) => v._id !== respData._id);
    state.value = currentValue;
    state.error = null;
    state.isLoading = false;
    state.isLoadingCreate = false;
    state.editDeckValue = null;
  },
  [fetchDeleteDeck.rejected]: (state, action) => {
    state.isLoading = false;
    state.isLoadingCreate = false;
    state.error = action.payload;
  },
};

export const decksSlice = createSlice({
  name: "decks",
  initialState: {
    value: [],
    error: null,
    editDeckValue: null,
    isLoading: false,
    isLoadingCreate: false,
  },
  reducers: {
    cleanDecks: (state, action) => {
      state.value = [];
    },
  },
  extraReducers: {
    ...fetchDecksReducer,
    ...fetchCreateDeckReducer,
    ...fetchUpdateDeckReducer,
    ...fetchUpdateDeckAvatarReducer,
    ...fetchEditDeckReducer,
    ...fetchDeleteDeckReducer,
  },
});

export const { cleanDecks } = decksSlice.actions;

export default decksSlice.reducer;

import { deleteApi, getApi, postApi, putApi } from "./general.service";

export const getFeedback = async (data) => {
    const resp = getApi(`/feedback`, data);
    return resp;
};
  
export const updateFeedback = async (data) => {
    const resp = putApi("/feedback", data);
    return resp;
};

export const deleteFeedback = async (data) => {
    const resp = deleteApi(`/feedback?_id=${data?._id}`, data);
    return resp;
};
  
export const createFeedback = async (data) => {
    const resp = postApi("/feedback", data);
    return resp;
};

export const createFeedbackPublic = async (data) => {
    const resp = postApi("/feedback/public", data);
    return resp;
};
import React, { useEffect, useState } from "react";
import styles from "./Video.module.sass";
import Card from "../../../../../components/Card";
import File from "../../../../../components/File";

const Video = ({ videoSelected, setVideoSelected }) => {
  const [fileURL, setFileURL] = useState("");

  const handleUploadFile = (e) => {
    setVideoSelected(e?.target.files[0]);
  };

  useEffect(() => {
    setFileURL(typeof videoSelected === "object" ? URL.createObjectURL(videoSelected) : videoSelected);
  }, [videoSelected]);

  return (
    <Card title="Video" classTitle="title-red">
      <div>
        {fileURL ? (
          <div>
            <video controls width="100%" src={fileURL} />
            <button
              onClick={() => {
                setFileURL("");
                setVideoSelected("");
              }}
            >
              Delete Video
            </button>
          </div>
        ) : (
          <File
            className={styles.field}
            onChange={handleUploadFile}
            title="Click or drop video"
            label="Upload video"
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
          />
        )}
      </div>
    </Card>
  );
};

export default Video;

import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router";
import TableComponent from '../../components/TableComponent';
import { getDeck, getDeckStats } from '../../api/decks.service';
import { BiAdjust } from 'react-icons/bi';
import Loader from '../../components/Loader';
import Card from '../../components/Card';

const DeckStats = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [deckSelected, setDeckSelected] = useState('')

    const [nextPageHash, setNextPageHash] = useState('')

    const columns = [
        { 
            header: 'User',
            accessor: 'user'
        },
        { 
            header: 'Level',
            accessor: 'level'
        },
        { 
            header: 'Time spent',
            accessor: 'timespent'
        },
        { 
            header: 'Known Cards',
            accessor: 'playedCards'
        },
        // { 
        //     header: 'Actions',
        //     accessor: 'actions'
        // },
    ]

    const [data, setData] = useState([])

    const { deckId } = useParams();

    const handleReturnDataTable = (data) =>{
        return data?.map((d)=>{
            return{
                user: {
                    data: d?.user?.name,
                    dataComponent: (
                        <div>
                            <div style={{ fontWeight: 800, color: 'black'}}>
                                {d?.user?.name}
                            </div>
                            <div style={{ fontSize: 14, color: 'gray'}}>
                                {d?.user?.email}
                            </div>
                        </div>
                    )
                },
                level: {
                    data: d?.user?.onboard?.level,
                    dataComponent: d?.user?.onboard?.level
                },
                timespent: {
                    data: d?.totalPlayed,
                    dataComponent: d?.totalPlayed
                },
                playedCards: {
                    data: d?.playedCards,
                    dataComponent: <div>{d?.playedCards}<span style={{fontSize: 16}}> / {d?.totalCards}</span></div>
                },
                actions: {
                    data: 'actions',
                    dataComponent: (
                        <div>
                            <BiAdjust />
                        </div>
                    )
                },
            }
        })
    }

    const handleGetDeckStats = async () =>{

        let dataDeck = await getDeck({ _id: deckId })
        await handleLoadMoreUsers()
        console.log("data get stats", data)
        console.log("dataDeck", dataDeck)
        setIsLoading(false)
        if(dataDeck?.status === 200){
            setDeckSelected(dataDeck?.data)
        }
    }

    useEffect(() => {
        handleGetDeckStats()   
    }, [deckId])
    
    const handleSearchUser = async (e) =>{
        let data = await getDeckStats({ _id: deckId, search: e })
        if(data?.data?.results?.length > 0){
            setData(handleReturnDataTable(data?.data?.results))
        }
    }

    const handleLoadMoreUsers = async () =>{
        const data = await getDeckStats({ _id: deckId, take: 20, nextPageHash })
        if(data?.data?.results?.length > 0){
            let newData = handleReturnDataTable(data?.data?.results)
            if(newData?.length > 0){
                setData(data => [
                  ...data, 
                  ...newData
                ])
                setNextPageHash(data?.data?.pagination?.nextPageHash)
            }
        }
    }

    return (
        <div>
            {isLoading ? (
                <Card>
                    <Loader />
                </Card>
            ) : (
                <TableComponent
                    data={data}
                    columns={columns}
                    handleSearchAPI={handleSearchUser}
                    onPageLoad={handleLoadMoreUsers}
                    title={`${deckSelected?.title}`}
                    searchPlaceholder="Search user..."
                    className="w-full"
                />
            )}
        </div>
    );
}

export default DeckStats;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getGrammars } from "../../api/grammar.service";

export const fetchGrammar = createAsyncThunk("grammar/fetchGrammar", async (data, { rejectWithValue }) => {
  const grammar = await getGrammars();
  console.log("****+grammar -> ", grammar);
  return grammar.data;
});

const fetchGrammarReducer = {
  [fetchGrammar.pending]: (state, action) => {
    state.isLoading = true;
    state.error = null;
  },
  [fetchGrammar.fulfilled]: (state, action) => {
    const grammar = action.payload.results;
    state.value = grammar;
    state.error = null;
    state.isLoading = false;
  },
  [fetchGrammar.rejected]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};


export const grammarSlice = createSlice({
    name: "grammar",
    initialState: {
      value: [],
      error: null,
      editGrammarValue: null,
      isLoading: false,
      isLoadingCreate: false,
    },
    reducers: {
      cleanGrammar: (state, action) => {
        state.value = [];
      },
    },
    extraReducers: {
      ...fetchGrammarReducer,
    },
  });
  
  export const { cleanGrammar } = grammarSlice.actions;
  
  export default grammarSlice.reducer;
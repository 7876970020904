import React, { useState } from "react";
import cn from "classnames";
import ReactDOMServer from 'react-dom/server';
import { Link } from "react-router-dom";
import Picker from 'emoji-picker-react'
import { Tooltip } from 'react-tooltip'
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Editor from "../../../components/Editor";
import Switch from "../../../components/Switch";
// import Tooltip from "../../../components/Tooltip";
import Dropdown from "../../../components/Dropdown";
import { levelOptions } from "..";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const NameAndDescription = ({
  className,
  title,
  setTitle,
  available,
  setAvailable,
  level,
  user,
  setLevel,
  descriptionState,
  setDescription,
  setDescriptionState,
  categorySelected,
  setCategory,
  subCategory,
  setSubCategory
}) => {

  const [categoryOptions, setCategoryOptions] = useState([])
  const [subCategoryOptions, setSubCategoryOptions] = useState([])

  const { category } = useSelector((state) => state);

  console.log("categorycategory", category)

  useEffect(() => {
    if(categorySelected && subCategory && category){
      setSubCategoryOptions(category?.value?.filter(val => val._id === categorySelected)?.[0]?.subCategories?.map((val)=>{
        return{
          label: val.title,
          value: val._id
        }
      }))
    }
  }, [categorySelected, subCategory, category])
  

  useEffect(() => {
    if(category?.value?.length > 0){
      setCategoryOptions(category?.value?.map((val)=>{
        return{
          label: val.title,
          value: val._id
        }
      }))
    }
  }, [category])

  const handleSetCategory = (e) =>{
    console.log("e selected", e)
    setCategory(e)
    setSubCategoryOptions(category?.value?.filter(val => val._id === e)?.[0]?.subCategories?.map((val)=>{
      return{
        label: val.title,
        value: val._id
      }
    }))
  }
  

  const onEmojiClick = (event, emojiObject) => {
    console.log(event.emoji, emojiObject)
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title="Name & info"
      classTitle="title-green"
      head={
        <Link className={cn("button-stroke button-small", styles.button)} to="/">
          <Icon name="arrow-left" size="24" />
          <span>Back</span>
        </Link>
      }
    >
      <div className={styles.switchContainer}>
        <div className={styles.label}>Deck available</div>
        <Switch className={styles.switch} value={available} onChange={() => setAvailable(!available)} />
      </div>

      <TextInput
        className={styles.field}
        label="Deck title"
        name="title"
        type="text"
        tooltip="Maximum 100 characters. No HTML or emoji allowed"
        onChange={(ev) => setTitle(ev.target.value)}
        value={title}
        required
      />
      {/* <Tooltip id="my-tooltip" />
      <div style={{
        width: '100%',
        height: '3rem',
        backgroundColor: 'brown'
      }} data-tooltip-id="my-tooltip" data-tooltip-html={ReactDOMServer.renderToStaticMarkup(<Picker onEmojiClick={onEmojiClick} />)} >

      </div> */}
      
      {/* <Editor
        onContentStateChange={setDescription}
        state={descriptionState}
        onChange={setDescriptionState}
        classEditor={styles.editor}
        label="Description"
        tooltip="Description"
      /> */}
      {user?.value?.role !== 'TEACHER' &&
        <>
          <Dropdown
            className={styles.field}
            classDropdownLabel={styles.label}
            value={categorySelected}
            setValue={handleSetCategory}
            options={categoryOptions}
            label="Master Category"
          />
          <Dropdown
            className={styles.field}
            classDropdownLabel={styles.label}
            value={subCategory}
            setValue={setSubCategory}
            options={subCategoryOptions}
            label="Category"
          />
        </>
      }
      <Dropdown
        className={styles.dropdown}
        classDropdownLabel={styles.label}
        value={level}
        setValue={setLevel}
        options={levelOptions}
        label="Level"
      />
      {/* <div className={styles.group}>
          <TextInput
            className={styles.field}
            label="Key features"
            name="value1"
            type="text"
            placeholder="Value"
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            required
          />
          <TextInput className={styles.field} name="value2" type="text" placeholder="Value" required />
          <TextInput className={styles.field} name="value3" type="text" placeholder="Value" required />
          <TextInput className={styles.field} name="value4" type="text" placeholder="Value" required />
        </div> */}
    </Card>
  );
};

export default NameAndDescription;

import React from 'react'
import cn from "classnames";
import { useState } from 'react';
import styles from './Context.module.sass'
import Card from '../../../components/Card';
import Dropdown from '../../../components/Dropdown';
import ContextInfo from './ContextInfo';
import { useEffect } from 'react';
import Word from './Word';
import { CgArrowLeftR, CgArrowRightR } from 'react-icons/cg'
import { TiInfoLarge } from 'react-icons/ti'

const Context = ({
    context1,
    setContext1,
    context2,
    setContext2,
    meaningWord,
    navigation,
    activeTab,
    setActiveTab,
    setMeaningWord
}) => {

   

    return (
        <Card
            title="Context"
            classTitle="title-red"
        >
            <div>
                <div style={{ borderBottom: '1px solid #EFEFEF', paddingBottom: 15}}>
                    <div style={{ display: 'flex', alignItems: 'center'}} className={cn(styles.nav, "tablet-hide")}>
                        {navigation.map((x, index) => (
                            <button
                                className={cn(styles.link, {
                                [styles.active]: x === activeTab,
                                })}
                                onClick={() => setActiveTab(x)}
                                key={index}
                            >
                                {x}
                            </button>
                        ))}
                        <small style={{ display: 'flex', marginLeft: 20, alignItems: 'center', color: 'lightgray'}}>
                            <span>
                                Use the arrows to switch tabs
                            </span>
                            <CgArrowLeftR size="20" style={{ marginLeft: 10 }}/>
                            <CgArrowRightR size="20" style={{ marginLeft: 5 }} />
                        </small>
                    </div>
                    <div className={cn(styles.dropdown, "tablet-show")}>
                        <Dropdown
                            classDropdownHead={styles.dropdownHead}
                            value={activeTab}
                            setValue={setActiveTab}
                            options={navigation}
                            small
                        />
                    </div>
                </div>
                <div style={{ marginTop: 25 }}>
                    {activeTab === navigation[0] && <ContextInfo info={meaningWord} setInfo={setMeaningWord} />}
                    {activeTab === navigation[1] && <ContextInfo info={context1} setInfo={setContext1} />}
                    {activeTab === navigation[2] && <ContextInfo info={context2} setInfo={setContext2} />}
                </div>
            </div>
        </Card>
    );
}

export default Context;
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/user.slice";
import decksReducer from "./decks/decks.slice";
import cardsReducer from "./cards/cards.slice";
import classesReducer from "./classes/classes.slice";
import categoryReducer from "./category/category.slice";
import grammarReducer from "./grammar/grammar.slice";
import classificationReducer from "./classification/classification.slice";
import bugsReducer from "./bugs/bugs.slice";
import feedbackReducer from "./feedback/feedback.slice";
import usersReducer from "./users/users.slice";

export default configureStore({
  reducer: {
    user: userReducer,
    decks: decksReducer,
    cards: cardsReducer,
    classes: classesReducer,
    category: categoryReducer,
    grammar: grammarReducer,
    classification: classificationReducer,
    bugs: bugsReducer,
    users: usersReducer,
    feedback: feedbackReducer
  },
});

import { deleteApi, getApi, postApi, putApi } from "./general.service";

export const getBugs = async (data) => {
    const resp = getApi(`/bug`, data);
    return resp;
};
  
export const updateBug = async (data) => {
    const resp = putApi("/bug", data);
    return resp;
};
  
export const createBug = async (data) => {
    const resp = postApi("/bug", data);
    return resp;
};

export const createBugPublic = async (data) => {
    const resp = postApi("/bug/public", data);
    return resp;
};
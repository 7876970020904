import React, { useState } from 'react'
import styles from './Preview.module.sass'
import Card from '../../../../../components/Card';
import { useHistory, useParams } from "react-router";
import cn from 'classnames';
import { BiEdit } from 'react-icons/bi';
import Icon from '../../../../../components/Icon';
import { AiFillSound } from 'react-icons/ai';
import Modal from '../../../../../components/Modal';
import { useEffect } from 'react';

const Preview = ({
    text,
    setText,
    isEdit
}) => {

    const history = useHistory();
    const { id } = useParams();

    const OPTIONS = ['Char', 'Pinyin']

    const [activeTab, setActiveTab] = useState(OPTIONS[0])
    const [visibleModal, setVisibleModal] = useState(false)

    const [textImported, setTextImported] = useState('')

    const handleImport = () =>{
        setTextImported(text)
        setVisibleModal(false)
    }

    useEffect(() => {
        if(isEdit && text){
            setTextImported(text)
        }
    }, [isEdit, text])
    


    return (
        <Card title="Text content" classTitle="title-blue">
            <Modal
                visible={visibleModal}
                onClose={() => setVisibleModal(false)}
            >
                <div>
                    <div style={{fontSize: 18}}>
                        Import text
                    </div>
                    <div style={{marginTop: 25, borderTop: '1px solid lightgray'}} />
                    <div style={{marginTop: 25}}>
                        <label style={{fontSize: 12, color: 'gray', paddingLeft: 3, paddingBottom: 10}}>
                            Short description
                        </label>
                        <textarea
                            rows="30"
                            style={{height: '200px'}}
                            value={text}
                            onChange={(e)=>setText(e.target.value)}
                            className={styles.input}
                            placeholder="Write here..."           
                        ></textarea>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                        <button onClick={handleImport} className={cn("button-black button-small", styles.favorite)}>
                            Import
                        </button>
                    </div>
                </div>
            </Modal>
            <div style={{justifyContent: 'space-between', display: 'flex', alignItems: 'center', width:'100%'}}>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 0}} className={cn(styles.nav, "tablet-hide")}>
                    <button
                        className={cn(styles.link,styles.active)}
                    >
                        Char
                    </button>
                </div>
            </div>
            <div style={{marginTop: 25}}>
                <textarea
                    rows="30"
                    style={{height: '200px', paddingTop: 10}}
                    className={styles.input}
                    placeholder="天上的小白羊 天上一群小白羊， 有的站着有的躺。
                    小白羊啊下来吧，不要在天上着了凉。地上河水清，
                    地上草儿肥，地上才是你们的家乡。"
                    value={textImported}
                    disabled            
                ></textarea>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <button onClick={()=>setVisibleModal(true)} className={cn("button-stroke button-small", styles.favorite)}>
                    Import
                </button>
                <button disabled={!isEdit} onClick={()=>history.push(`/texts/content/${id}`)} style={{backgroundColor:'black'}} className={cn("button-black button-small", styles.favorite)}>
                    <Icon name="edit" />
                    Edit content
                </button>
            </div>
        </Card>
    );
}

export default Preview;
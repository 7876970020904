import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { createUser, deleteUser, getUser, getUsers, updateUser } from "../../api/user.service";
import { copyOf } from "../../utils";

export const fetchUsers = createAsyncThunk("users/fetchUsers", async (data, { rejectWithValue }) => {
  const users = await getUsers(data);
  console.log("****+users -> ", users);
  return users.data;
});

const fetchUsersReducer = {
  [fetchUsers.pending]: (state, action) => {
    state.isLoading = true;
    state.error = null;
  },
  [fetchUsers.fulfilled]: (state, action) => {
    const users = action.payload.results;
    if (users && Array.isArray(users)) {
      state.value = users;
      // state.value.sort((a, b) => {
      //   return !a.stat.lastPlayed
      //     ? 1
      //     : !b.stat.lastPlayed
      //     ? -1
      //     : a.stat.lastPlayed < b.stat.lastPlayed
      //     ? 1
      //     : a.stat.lastPlayed > b.stat.lastPlayed
      //     ? -1
      //     : 0;
      // });
    }

    state.error = null;
    state.isLoading = false;
  },
  [fetchUsers.rejected]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const fetchEditUser = createAsyncThunk("users/fetchEditUser", async (data, { rejectWithValue }) => {
  const users = await getUser(data);
  console.log("****+getuser -> ", users);
  return users.data;
});

const fetchEditUserReducer = {
  [fetchEditUser.pending]: (state, action) => {
    state.isLoading = true;
    state.error = null;
    state.editUserValue = null;
  },
  [fetchEditUser.fulfilled]: (state, action) => {
    const user = action.payload;
    state.editUserValue = user;
    state.error = null;
    state.isLoading = false;
  },
  [fetchEditUser.rejected]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const fetchCreateUser = createAsyncThunk("users/fetchCreateUser", async (data, { rejectWithValue }) => {
  const users = await createUser(data);
  console.log("****+user created -> ", users);
  return users.data;
});

const fetchCreateUserReducer = {
  [fetchCreateUser.pending]: (state, action) => {
    state.isLoadingCreate = true;
    state.isLoading = true;
    state.error = null;
  },
  [fetchCreateUser.fulfilled]: (state, action) => {
    const respData = action.payload;
    state.value.push(respData);
    state.error = null;
    state.editUserValue = null;
    state.isLoadingCreate = false;
    state.isLoading = false;
  },
  [fetchCreateUser.rejected]: (state, action) => {
    state.isLoadingCreate = false;
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const fetchUpdateUser = createAsyncThunk("users/fetchUpdateUser", async (data, { rejectWithValue }) => {
  const user = await updateUser(data);
  console.log("****+users -> ", user);
  return user.data;
});

const fetchUpdateUserReducer = {
  [fetchUpdateUser.pending]: (state, action) => {
    state.isLoading = true;
    state.isLoadingCreate = true;
    state.error = null;
  },
  [fetchUpdateUser.fulfilled]: (state, action) => {
    const respData = action.payload;
    const currentValue = copyOf(current(state.value)).filter((v) => v._id !== respData._id);
    state.value = currentValue;
    state.value.unshift(respData);
    state.error = null;
    state.isLoading = false;
    state.isLoadingCreate = false;
    state.editUserValue = null;
  },
  [fetchUpdateUser.rejected]: (state, action) => {
    state.isLoading = false;
    state.isLoadingCreate = false;
    state.error = action.payload;
  },
};

export const fetchDeleteUser = createAsyncThunk("users/fetchDeleteUser", async (data, { rejectWithValue }) => {
  const user = await deleteUser(data);
  console.log("****+users -> ", user);
  return data;
});

const fetchDeleteUserReducer = {
  [fetchDeleteUser.pending]: (state, action) => {
    state.isLoading = true;
    state.isLoadingCreate = true;
    state.error = null;
  },
  [fetchDeleteUser.fulfilled]: (state, action) => {
    const respData = action.payload;
    const currentValue = copyOf(current(state.value)).filter((v) => v._id !== respData._id);
    state.value = currentValue;
    state.error = null;
    state.isLoading = false;
    state.isLoadingCreate = false;
    state.editUserValue = null;
  },
  [fetchDeleteUser.rejected]: (state, action) => {
    state.isLoading = false;
    state.isLoadingCreate = false;
    state.error = action.payload;
  },
};

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    value: [],
    error: null,
    editUserValue: null,
    isLoading: false,
    isLoadingCreate: false,
  },
  reducers: {
    cleanUsers: (state, action) => {
      state.value = [];
    },
  },
  extraReducers: {
    ...fetchUsersReducer,
    ...fetchCreateUserReducer,
    ...fetchUpdateUserReducer,
    ...fetchEditUserReducer,
    ...fetchDeleteUserReducer,
  },
});

export const { cleanUsers } = usersSlice.actions;

export default usersSlice.reducer;

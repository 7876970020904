import React from "react";
import styles from "./Content2.module.sass";
import { useHistory, useParams } from "react-router";
import Popover from "../../../components/Popover";
import classNames from "classnames";
import { CircularProgressbar } from "react-circular-progressbar";
import Icon from "../../../components/Icon";
import dayjs from "dayjs";
import { getTextID, getTextsContent, putTextsContent } from "../../../api/texts.service";
import { useState } from "react";
import { nanoid } from "nanoid";
import { useEffect } from "react";
import Chinese from "./components/Chinese";
import Translate from "./components/Translate";
import { toast } from "sonner";
import Modal from "../../../components/Modal";
import Questions from "./components/Questions";
import { useRef } from "react";

const LANGS = ["en", "es", "fr", "ru", "pt"];

const NAVS = ["Content", "Questions"];

const Content2 = () => {
  const { id } = useParams();

  const myRefs = useRef([]);

  const [chapters, setChapters] = useState([]);

  const [chapterIndexSelected, setChapterIndexSelected] = useState(0);

  const [level, setLevel] = useState([]);

  const [dataTextLevel, setDataTextLevel] = useState("");

  const [visibleModal, setVisibleModal] = useState(false);
  const [textImport, setTextImport] = useState("");

  const [navSelected, setNavSelected] = useState("Content");

  const [loadingTranslation, setLoadingTranslation] = useState(false);

  const [questions, setQuestions] = useState([]);

  const handleGetContent = async () => {
    let response = await getTextsContent({ _id: id });
    if (response?.data) {
      let responseParsed = response?.data;

      responseParsed?.forEach((c) => {
        c?.sentences?.forEach((sentc) => {
          let translations = sentc.translations;

          LANGS.forEach((lang) => {
            if (!translations.find((tt) => tt.lang === lang)) {
              translations.push({
                active: true,
                lang,
                trans: "",
              });
            }
          });

          sentc.id = nanoid();
          sentc.translations = translations;
        });
      });

      if (responseParsed?.length > 0) {
        setChapters(responseParsed);
      } else {
        anadirCapitulo();
      }
      console.log("Chapters", responseParsed);
    }
  };

  const handleGetTextLevel = async () => {
    let response = await getTextID("", id);

    if (response?.data) {
      setLevel(response?.data?.levelText);
      setDataTextLevel(response?.data);
    }
    console.log("response level", response);
  };

  useEffect(() => {
    if (id) {
      handleGetContent();
      handleGetTextLevel();
    }
  }, [id]);

  const AnalysisComponent = () => {
    return (
      <div className={styles.analysis}>
        <div>
          <div style={{ textAlign: "end", color: "lightgray" }}>{dataTextLevel?.analysis?.words} words</div>
          <div style={{ textAlign: "end" }}>{dataTextLevel?.analysis?.characters} chinese chars</div>
        </div>
        <div
          style={{
            fontSize: 24,
            color: "#CE2121",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "50px",
            marginLeft: 20,
            height: "50px",
          }}
        >
          <CircularProgressbar
            width={50}
            height={50}
            value={dataTextLevel?.analysis?.characters}
            maxValue={dataTextLevel?.analysis?.words}
            text={dataTextLevel?.analysis?.grade}
            styles={{
              path: {
                // Path color
                stroke: `#E94D4D`,
                strokeLinecap: "butt",
              },
              trail: {
                stroke: "#E8E8E8",
              },
              text: {
                // Text color
                fill: "#CE2121",
                // Text size
                fontSize: "36px",
              },
            }}
          />
        </div>
      </div>
    );
  };

  const handlePutContent = async () => {
    let response = await putTextsContent(chapters, id);

    console.log("response", response);

    if (response?.status === 200) {
      toast.success("Saved successfully");
      handleGetContent(); // Añadido a posteriori probar si funciona
    } else {
      toast.error("There was an error.");
    }
  };

  const handleAddImportText = async () => {
    let translations = [];

    LANGS.forEach((lang) => {
      translations.push({
        active: true,
        lang,
        trans: "",
      });
    });

    const uniqueObjects = { cards: [], processRawText: true, text: textImport, translations, id: nanoid() };

    let chapters_ = chapters.map((capitulo, indice) => {
      if (indice === chapterIndexSelected) {
        return { ...capitulo, sentences: [...capitulo.sentences, uniqueObjects] };
      }
      return capitulo;
    });

    let response = await putTextsContent(chapters_, id);

    if (response) {
      toast.success("Text imported succesfully");
      setVisibleModal(false);
      setTextImport("");
      handleGetContent();
    }
  };

  const handleTranslateAll = async () => {
    setLoadingTranslation(true);
    const url = `${process.env.REACT_APP_API_URL}/translation/deepl`; // Reemplaza esta URL por la URL real de la API
    const authorizationHeader = { Authorization: `Bearer ${JSON.parse(localStorage?.getItem("user")).token}` }; // Crea el encabezado Authorization

    let TRANS_LANGS = ["es", "pt", "fr", "ru"];

    let chapters_ = chapters;

    for (let [indexChapter, chapter] of chapters.entries()) {
      for (let [indexSentence, sentence] of chapter.sentences.entries()) {
        if (sentence?.translations?.find((c) => c?.lang === "en")?.trans) {
          for (let lang_ of TRANS_LANGS) {
            let response = await fetch(url, {
              method: "POST",
              body: JSON.stringify({
                title: sentence?.translations?.find((c) => c?.lang === "en")?.trans,
                lang: lang_,
              }),
              headers: {
                "Content-Type": "application/json",
                ...authorizationHeader, // Agrega el encabezado Authorization a los encabezados de solicitud
              },
            });
            let response2 = await response.json();

            let translation = response2?.translations?.[0]?.text;
            if (translation) {
              chapters_ = chapters_.map((capitulo, indice) => {
                if (indice === indexChapter) {
                  // Actualiza solo las frases del capítulo específico
                  const frasesActualizadas = capitulo.sentences.map((frase, idx) => {
                    if (idx === indexSentence) {
                      // Actualiza solo las traducciones de la frase específica
                      const traduccionesActualizadas = frase.translations.map((traduccion, index) => {
                        if (lang_ === traduccion.lang) {
                          // Retorna la nueva traducción actualizada
                          return {
                            ...traduccion,
                            ...{
                              active: true,
                              lang: lang_,
                              trans: translation,
                            },
                          };
                        }
                        return traduccion;
                      });
                      return { ...frase, translations: traduccionesActualizadas };
                    }
                    return frase;
                  });
                  return { ...capitulo, sentences: frasesActualizadas };
                }
                return capitulo;
              });
            }
          }
        }
      }
    }

    setChapters(chapters_);
    setLoadingTranslation(false);
    toast.success("Translated Succesfully!");
  };

  const traducirTextoFrase = async (indiceCapitulo, indiceFrase, texto, idiomaATraducir) => {
    const url = `${process.env.REACT_APP_API_URL}/translation/deepl`; // Reemplaza esta URL por la URL real de la API
    const authorizationHeader = { Authorization: `Bearer ${JSON.parse(localStorage?.getItem("user")).token}` }; // Crea el encabezado Authorization
    let response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        title: texto,
        lang: idiomaATraducir,
      }),
      headers: {
        "Content-Type": "application/json",
        ...authorizationHeader, // Agrega el encabezado Authorization a los encabezados de solicitud
      },
    });
    let response2 = await response.json();
    let translation = response2?.translations?.[0]?.text;

    editarTraduccionDeFrase(indiceCapitulo, indiceFrase, idiomaATraducir, {
      active: true,
      lang: idiomaATraducir,
      trans: translation,
    });
  };

  const anadirCapitulo = () => {
    const nuevoCapitulo = {
      sentences: [], // Un nuevo capítulo probablemente comience sin frases
      text: "",
      order: chapters.length, // Asume que el orden sigue el índice de inserción
      processRawText: false, // O true, según la lógica de tu aplicación
    };

    setChapters([...chapters, nuevoCapitulo]);
  };

  const editarCapitulo = (indiceCapitulo, nuevoContenido) => {
    setChapters(
      chapters.map((capitulo, indice) => {
        if (indice === indiceCapitulo) {
          return { ...capitulo, ...nuevoContenido };
        }
        return capitulo;
      })
    );
  };

  const editarFraseDeCapitulo = (indiceCapitulo, indiceFrase, nuevoContenidoFrase) => {
    setChapters(
      chapters.map((capitulo, indice) => {
        if (indice === indiceCapitulo) {
          return {
            ...capitulo,
            sentences: capitulo.sentences.map((frase, idx) => {
              if (idx === indiceFrase) {
                return { ...frase, ...nuevoContenidoFrase };
              }
              return frase;
            }),
          };
        }
        return capitulo;
      })
    );
  };

  const anadirFraseACapitulo = (indiceCapitulo, nuevaFrase) => {
    setChapters(
      chapters.map((capitulo, indice) => {
        if (indice === indiceCapitulo) {
          return { ...capitulo, sentences: [...capitulo.sentences, nuevaFrase] };
        }
        return capitulo;
      })
    );
  };

  const anadirCardAFrase = (indiceCapitulo, indiceFrase, nuevoCard) => {
    setChapters(
      chapters.map((capitulo, indice) => {
        if (indice === indiceCapitulo) {
          return {
            ...capitulo,
            sentences: capitulo.sentences.map((frase, idx) => {
              if (idx === indiceFrase) {
                return { ...frase, cards: [...frase.cards, nuevoCard] };
              }
              return frase;
            }),
          };
        }
        return capitulo;
      })
    );
  };

  const editarTraduccionDeFrase = (indiceCapitulo, indiceFrase, idioma, nuevaTraduccion) => {
    setChapters(
      chapters.map((capitulo, indice) => {
        if (indice === indiceCapitulo) {
          // Actualiza solo las frases del capítulo específico
          const frasesActualizadas = capitulo.sentences.map((frase, idx) => {
            if (idx === indiceFrase) {
              // Actualiza solo las traducciones de la frase específica
              const traduccionesActualizadas = frase.translations.map((traduccion, index) => {
                if (idioma === traduccion.lang) {
                  // Retorna la nueva traducción actualizada
                  return { ...traduccion, ...nuevaTraduccion };
                }
                return traduccion;
              });
              return { ...frase, translations: traduccionesActualizadas };
            }
            return frase;
          });
          return { ...capitulo, sentences: frasesActualizadas };
        }
        return capitulo;
      })
    );
  };

  const eliminarFraseDeCapitulo = (indiceCapitulo, indiceFrase) => {
    setChapters(
      chapters.map((capitulo, indice) => {
        if (indice === indiceCapitulo) {
          // Filtra la frase que no coincide con el índice dado
          const nuevasFrases = capitulo.sentences.filter((_, idx) => idx !== indiceFrase);
          return { ...capitulo, sentences: nuevasFrases };
        }
        return capitulo;
      })
    );
  };

  const eliminarCardDeFrase = (indiceCapitulo, indiceFrase, indiceCard) => {
    setChapters(
      chapters.map((capitulo, indice) => {
        if (indice === indiceCapitulo) {
          // Actualiza solo las frases del capítulo específico
          const frasesActualizadas = capitulo.sentences.map((frase, idx) => {
            if (idx === indiceFrase) {
              // Filtra el card que no coincide con el índice dado
              const nuevosCards = frase.cards.filter((_, i) => i !== indiceCard);
              return { ...frase, cards: nuevosCards };
            }
            return frase;
          });
          return { ...capitulo, sentences: frasesActualizadas };
        }
        return capitulo;
      })
    );
  };

  const anadirCardDespuesDeOtra = (indiceCapitulo, indiceFrase, indiceCard, nuevaCard) => {
    setChapters(
      chapters.map((capitulo, indexCap) => {
        if (indexCap === indiceCapitulo) {
          // Encuentra el capítulo específico y mapea sus frases
          const frasesActualizadas = capitulo.sentences.map((frase, indexFrase) => {
            if (indexFrase === indiceFrase) {
              // Encuentra la frase específica y construye las nuevas 'cards'
              const cardsActualizadas = [
                ...frase.cards.slice(0, indiceCard + 1), // Tomar todos los elementos hasta el índice específico
                nuevaCard, // Incluir la nueva 'card'
                ...frase.cards.slice(indiceCard + 1), // Incluir el resto de las 'cards' después del índice
              ];
              return { ...frase, cards: cardsActualizadas };
            }
            return frase;
          });
          return { ...capitulo, sentences: frasesActualizadas };
        }
        return capitulo;
      })
    );
  };

  const cambiarCardDeFrase = (indiceCapitulo, indiceFrase, indiceCard, nuevaCard) => {
    setChapters(
      chapters.map((capitulo, indexCap) => {
        if (indexCap === indiceCapitulo) {
          // Encuentra el capítulo específico y mapea sus frases
          const frasesActualizadas = capitulo.sentences.map((frase, indexFrase) => {
            if (indexFrase === indiceFrase) {
              // Encuentra la frase específica y actualiza la 'card'
              const cardsActualizadas = frase.cards.map((card, indexC) => {
                if (indexC === indiceCard) {
                  // Retorna la nueva 'card' actualizada
                  return { ...card, ...nuevaCard };
                }
                return card;
              });
              return { ...frase, cards: cardsActualizadas };
            }
            return frase;
          });
          return { ...capitulo, sentences: frasesActualizadas };
        }
        return capitulo;
      })
    );
  };

  const propsFunctions = {
    editarCapitulo,
    editarFraseDeCapitulo,
    anadirFraseACapitulo,
    anadirCardAFrase,
    editarTraduccionDeFrase,
    eliminarFraseDeCapitulo,
    handleGetContent,
    eliminarCardDeFrase,
    traducirTextoFrase,
    questions,
    setQuestions,
    myRefs,
    id,
    anadirCardDespuesDeOtra,
    cambiarCardDeFrase,
    chapters,
  };

  return (
    <div className={styles.spacey}>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <div>
          <div style={{ fontSize: 18 }}>Import text</div>
          <div style={{ marginTop: 25, borderTop: "1px solid lightgray" }} />
          <div style={{ marginTop: 25 }}>
            <label style={{ fontSize: 12, color: "gray", paddingLeft: 3, paddingBottom: 10 }}>Short description</label>
            <textarea
              rows="30"
              style={{ height: "200px" }}
              value={textImport}
              onChange={(e) => setTextImport(e.target.value)}
              className={styles.input}
              placeholder="Write here..."
            ></textarea>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <button onClick={handleAddImportText} className={classNames("button-black button-small", styles.favorite)}>
              Import
            </button>
          </div>
        </div>
      </Modal>
      <div className={styles.header}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className={styles.info}>
            <span style={{ fontSize: 28, fontWeight: "bold" }}>Text content</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <Popover title="Text analysis" actionable={<AnalysisComponent />}>
              <div>
                {level?.map((c) => (
                  <div
                    style={{
                      borderBottom: "1px #EFEFEF solid",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: 10,
                    }}
                  >
                    <span style={{ color: "#5595E1", width: 150 }}>{c?.title}</span>
                    <span style={{ fontWeight: "bold", width: 100 }}>{c?.value} words</span>
                    <span style={{ color: "#6F767E" }}>{c?.percentage}%</span>
                  </div>
                ))}
                <div
                  style={{
                    borderBottom: "1px #EFEFEF solid",
                    marginTop: 40,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 10,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Avg. Level</span>
                  <span style={{ color: "#6F767E" }}>{dataTextLevel?.analysis?.avgLevel}</span>
                </div>
                <div
                  style={{
                    borderBottom: "1px #EFEFEF solid",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 10,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Grade</span>
                  <span style={{ color: "#FF6A55" }}>{dataTextLevel?.analysis?.grade}</span>
                </div>
              </div>
            </Popover>
            <button
              onClick={() => setVisibleModal(true)}
              style={{ marginLeft: 20 }}
              className={classNames("button-stroke", styles.button)}
            >
              <span>Import text</span>
            </button>
            <button
              onClick={() => handleTranslateAll()}
              style={{ marginLeft: 20 }}
              className={classNames("button-stroke", styles.button)}
            >
              {loadingTranslation ? (
                <>
                  <svg width="24" height="24" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                        <stop stop-color="#000" stop-opacity="0" offset="0%" />
                        <stop stop-color="#000" stop-opacity=".631" offset="63.146%" />
                        <stop stop-color="#000" offset="100%" />
                      </linearGradient>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                      <g transform="translate(1 1)">
                        <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
                          <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="0.9s"
                            repeatCount="indefinite"
                          />
                        </path>
                        <circle fill="#000" cx="36" cy="18" r="1">
                          <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="0.9s"
                            repeatCount="indefinite"
                          />
                        </circle>
                      </g>
                    </g>
                  </svg>
                </>
              ) : (
                <span>Auto-translate</span>
              )}
            </button>
          </div>
        </div>
      </div>

      <div style={{ minHeight: "90vh", paddingBottom: "20rem", padding: "0px 40px 350px 40px" }}>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "2em" }}
          className={classNames(styles.nav, "tablet-hide")}
        >
          {NAVS.map((x, index) => (
            <button
              className={classNames(styles.link, {
                [styles.active]: x === navSelected,
              })}
              onClick={() => setNavSelected(x)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>

        {navSelected === "Content" && (
          <>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                padding: "12px",
                display: "flex",
                alignItems: "center",
                marginTop: "1em",
              }}
            >
              {chapters?.map((chapter, indexChapter) => (
                <div
                  style={{
                    marginLeft: indexChapter === 0 ? "12px" : "24px",
                    color: chapterIndexSelected === indexChapter ? "#2A85FF" : "gray",
                    cursor: "pointer",
                  }}
                  onClick={() => setChapterIndexSelected(indexChapter)}
                >
                  Chapter {indexChapter + 1}
                </div>
              ))}
              <div
                style={{
                  marginLeft: "24px",
                  cursor: "pointer",
                  color: "lightgray",
                }}
                onClick={anadirCapitulo}
              >
                + Add chapter
              </div>
            </div>
            {chapters?.map((chapter, i) => {
              if (i === chapterIndexSelected) {
                return (
                  <div key={i} className={styles.columnas}>
                    <div className={styles.filas}>
                      <Chinese chapter={chapter} chapterIndexSelected={i} {...propsFunctions} />
                    </div>
                    <div className={styles.filas}>
                      <Translate chapter={chapter} chapterIndexSelected={i} {...propsFunctions} />
                    </div>
                  </div>
                );
              }
            })}
          </>
        )}

        {navSelected === "Questions" && <Questions {...propsFunctions} />}
      </div>

      <div
        style={{
          background: "black",
          width: "100%",
          position: "sticky",
          left: 0,
          zIndex: 99,
          bottom: 0,
        }}
      >
        <div
          style={{
            background: "white",
            padding: 14,
            position: "sticky",
            bottom: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className={styles.info}>
            <Icon name="check-all" size="24" />
            {"Last edit "}
            <span>{dayjs(new Date()).format("MMM DD, YYYY - HH:mm[h]")}</span>
            <u style={{ marginLeft: 8 }}>@Alfonso</u>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <button style={{marginLeft: 20}} className={classNames("button-stroke", styles.button)} >
                            <span>Draft</span>
                        </button> */}
            <button
              onClick={handlePutContent}
              style={{ marginLeft: 20 }}
              className={classNames("button", styles.button)}
            >
              <span>Save</span>
            </button>
            <div style={{ marginLeft: 20 }}>
              {/* <Popover title="Actions" actionable={<Icon name="more-horizontal" size="24" />}>
                                <div>
                                    Holahola
                                </div>
                            </Popover> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content2;
